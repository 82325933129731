import React from "react";

/**
 * CmsContent is a component that displays the content of a specific field
 * from the current webpage's content list or a provided content list.
 */
const CmsContent = (props) => {
  // Extract contentList from currentWebpage if it exists, otherwise use provided contentList
  const contentList = props.currentWebpage
    ? props.currentWebpage.contentList
    : props.contentList;

  // Check if contentList exists
  if (contentList) {
    // Find the item in contentList with the specified fieldname
    const item = contentList.find((item) => item.fieldname === props.fieldname);

    // If the item is found, return a div with its content
    if (item) {
      if (props.formatting && props.formatting === "text") {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(item.content, "text/html");
        return <span>{htmlDoc.body.textContent}</span>;
      } else {
        return <div dangerouslySetInnerHTML={{ __html: item.content }} />;
      }
    } else {
      // If the item is not found, log a message to the console and return an empty div
      console.log(`Field "${props.fieldname}" not found in contentList`);
      return <div />;
    }
  } else {
    // If contentList does not exist, return an empty div
    return <div />;
  }
};

export default CmsContent;
