import { DateTime } from "luxon";
import { Card, Grid, Box, Typography, Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import HotelIcon from "@mui/icons-material/Hotel";
import ZzzIcon from "../custom/ZzzIcon";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload }) => {
  // Helper function to format durations
  const formatDuration = (time) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time % 1) * 60);
    if (hours >= 1) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

  if (!active || !payload) {
    return null;
  }
  if (payload.length === 0) {
    return null;
  }
  if (!payload[0].payload.completed) {
    return null;
  }

  const data = payload[0].payload;
  const sleepQuality = parseInt(data?.sleepQuality || 0, 10); // Convert string to number

  return (
    <Card className="px-2 pt-0 pb-2" sx={{ maxWidth: 250 }}>
      <Grid container spacing={0} alignItems="flex-end">
        <Grid item xs={12} sm={8}>
          <Typography
            noWrap
            variant="overline"
            color="textSecondary"
            fontSize={10}
            sx={{ lineHeight: 1 }}
          >
            Sleep Quality
          </Typography>
          <Box display="flex" alignItems="center">
            {[...Array(sleepQuality)].map((_, i) => (
              <StarIcon
                key={`filled-${i}`}
                color="primary"
                sx={{ fontSize: "0.9rem" }}
              />
            ))}
            {/* Render empty stars */}
            {[...Array(5 - sleepQuality)].map((_, i) => (
              <StarBorderIcon
                key={`empty-${i}`}
                color="textSecondary"
                sx={{ fontSize: "0.9rem" }}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            noWrap
            variant="overline"
            color="textSecondary"
            fontSize={10}
            sx={{ lineHeight: 1 }}
          >
            Sleep Efficiency
          </Typography>
          <Typography
            noWrap
            variant="h6"
            color="primary"
            sx={{ lineHeight: 0.7 }}
          >
            {Math.round(data.se)}%
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className="mt-2 mb-1" />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
              <HotelIcon size="small" color="primary" className="me-2" />
              {data.bedClock} - {data.riseClock}
            </Grid>
            <Grid item xs={4}>
              {formatDuration(data.tib)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
              <ZzzIcon
                color="primary"
                className="me-2"
                style={{ color: "#8A287E", width: 24, height: 24 }}
              />
              {DateTime.fromSeconds((data.sleepOnsetTime % 24) * 60 * 60)
                .toUTC()
                .toFormat("HH:mm")}{" "}
              -{" "}
              {DateTime.fromSeconds((data.finWakeTime % 24) * 60 * 60)
                .toUTC()
                .toFormat("HH:mm")}
            </Grid>
            <Grid item xs={4}>
              {formatDuration(data.tst)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default CustomTooltip;
