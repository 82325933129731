// =================================================
// FUNCTION
// -------------------------------------------------
// Uses recursion to find an object by it's unique Id
// in a nested array of objects
export const findNestedObject = (arr, id) => {
  const keys = ["gridContainerList", "gridItemList", "articleList", "rowList"];
  // eslint-disable-next-line
  return arr.reduce((result, next) => {
    if (result) {
      return result;
    }
    if (next._id && next._id === id) {
      return next;
    }
    for (let k = 0; k < keys.length; k++) {
      if (Array.isArray(next[keys[k]])) {
        return findNestedObject(next[keys[k]], id);
      }
    }
  }, null);
};

export const findAndUpdateNestedObject = (unmutableobj, targetId, update) => {
  // Deep copy the object to avoid mutating the original
  let obj = JSON.parse(JSON.stringify(unmutableobj));
  if (Array.isArray(obj)) {
    // Iterate through each item in the array
    obj = obj.map((item) => findAndUpdateNestedObject(item, targetId, update));
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object and contains the target ID, update it
    if (Object.hasOwn(obj, "_id") && obj._id === targetId) {
      Object.assign(obj, update);
    }

    // Recursively update any nested objects
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        obj[key] = findAndUpdateNestedObject(obj[key], targetId, update);
      }
    }
  }
  return obj; // Return the updated object
};
