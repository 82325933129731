// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Link from "@mui/icons-material/Link";
// -----------------------------------------------
// Support functions
import { defaultOptions } from "../../supportFunc/defaultSurveys";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleSettings = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleSettings",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const canBeRequired =
    props.obj.category !== "media" &&
    props.obj.type !== "checkboxes" &&
    props.obj.type !== "checkboxes-grid";
  const canBeEncrypted =
    props.obj.type === "textbox" || props.obj.type === "textbox-long";
  // -----------------------------------------------
  const hasURL = props.obj.type === "image" || props.obj.type === "video-file";
  // -----------------------------------------------
  const isYouTube = props.obj.type === "video-youtube";
  // -----------------------------------------------
  const hasFormLabel =
    props.obj.type === "textbox" ||
    props.obj.type === "textbox-long" ||
    props.obj.type === "number" ||
    props.obj.type === "radio-vertical" ||
    props.obj.type === "radio-horizontal" ||
    props.obj.type === "dropdown-menu" ||
    props.obj.type === "checkboxes" ||
    props.obj.type === "date" ||
    props.obj.type === "time" ||
    props.obj.type === "date-time" ||
    props.obj.type === "slider-horizontal";
  // -----------------------------------------------
  const hasInputLabel =
    props.obj.type === "textbox" ||
    props.obj.type === "textbox-long" ||
    props.obj.type === "number" ||
    props.obj.type === "dropdown-menu" ||
    props.obj.type === "grouped-dropdown-menu";
  // -----------------------------------------------
  // Article types
  const articleTypes =
    props.obj.category === "media"
      ? [
          { type: "text", label: "Text" },
          { type: "image", label: "Image" },
          { type: "image-bento-box", label: "Image bento box" },
          { type: "video-file", label: "Video by URL" },
          { type: "video-youtube", label: "YouTube video" },
        ]
      : [
          { type: "textbox", label: "Textbox" },
          { type: "textbox-long", label: "Textbox (long text)" },
          { type: "number", label: "Numeric" },
          { type: "radio-vertical", label: "Radio (vertical)" },
          { type: "radio-horizontal", label: "Radio (horizontal)" },
          { type: "dropdown-menu", label: "Dropdown menu" },
          { type: "grouped-dropdown-menu", label: "Grouped dropdown menu" },
          { type: "checkboxes", label: "Checkboxes", divider: true },
          { type: "duration", label: "Duration" },
          { type: "date", label: "Date" },
          { type: "time", label: "Time" },
          { type: "date-time", label: "Date and time" },
          { type: "slider-horizontal", label: "Slider", divider: true },
          { type: "textbox-list", label: "Textbox list" },
          { type: "number-list", label: "Numeric list" },
          {
            type: "dropdown-menu-list",
            label: "Dropdown menu list",
            divider: true,
          },
          { type: "radio-grid", label: "Radio grid" },
          { type: "checkboxes-grid", label: "Checkboxes grid" },
          { type: "textbox-grid", label: "Textbox grid" },
          { type: "number-grid", label: "Numeric grid" },
          { type: "dropdown-menu-grid", label: "Dropdown menu grid" },
        ];
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      // When the type changes, set the default options and copy over previous options
      if (key === "type") {
        let newOptions = defaultOptions[value]
          ? defaultOptions[value].reduce((obj, item) => {
              obj[item.key] =
                prevState.options && prevState.options[item.key]
                  ? prevState.options[item.key]
                  : item.default;
              return obj;
            }, {})
          : null;
        props.setOptions(newOptions);
      }
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Typography variant="h4" className="d-inline">
        {t("Article type")}
      </Typography>
      {props.dependencies &&
        Object.keys(props.dependencies).some((dep) =>
          dep.includes(props.articleId),
        ) && (
          <Chip
            icon={<Link className="ms-2" />}
            label={t("dependent")}
            size="small"
            variant="outlined"
            color="warning"
            className="ms-2 border-white"
          />
        )}
      {props.scoring &&
        props.scoring.some(
          (score) =>
            (score.arithmetic &&
              score.arithmetic.some((a) =>
                a.aliasId.includes(props.articleId),
              )) ||
            (score.grouping &&
              score.grouping.aliasId.includes(props.articleId)),
        ) && (
          <Chip
            icon={<Link className="ms-2" />}
            label={t("scored")}
            size="small"
            variant="outlined"
            color="success"
            className="ms-2 border-white"
          />
        )}
      <FormControl
        fullWidth
        disabled={
          (props.dependencies &&
            Object.keys(props.dependencies).some((dep) =>
              dep.includes(props.articleId),
            )) ||
          (props.scoring &&
            props.scoring.some(
              (score) =>
                (score.arithmetic &&
                  score.arithmetic.some((a) =>
                    a.aliasId.includes(props.articleId),
                  )) ||
                (score.grouping &&
                  score.grouping.aliasId.includes(props.articleId)),
            ))
        }
        variant="filled"
        color="secondary"
        className="mb-3 mt-2"
      >
        <Select
          hiddenLabel
          name="type"
          value={props.obj.type}
          className="answer-text-green"
          color="secondary"
          onChange={(e) => {
            handleSetObj(e.target.name, e.target.value);
            handleSetObj("encrypt", false);
            if (props.obj.rowList) {
              const newRowList = JSON.parse(JSON.stringify(props.obj.rowList));
              newRowList.forEach((row) => (row.encrypt = false));
              handleSetObj("rowList", newRowList);
            }
          }}
        >
          {articleTypes.map((t) => (
            <MenuItem
              key={`article-type-${t.type}`}
              value={t.type}
              divider={t.divider ? true : false}
            >
              {t.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container alignItems="center">
        {(canBeRequired || hasURL || hasFormLabel || hasInputLabel) && (
          <>
            <Typography variant="h4" className="w-100">
              <Divider className="mb-3" />
              {t("Settings")}
            </Typography>
          </>
        )}
        {canBeRequired && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={props.obj.required}
                  onChange={(e) => handleSetObj("required", e.target.checked)}
                />
              }
              label={
                props.obj.required
                  ? t("Answer required")
                  : t("Answer is optional")
              }
              className="mt-2 mb-0"
            />
          </Grid>
        )}
        {canBeEncrypted && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={props.obj.encrypt}
                  onChange={(e) => handleSetObj("encrypt", e.target.checked)}
                />
              }
              label={
                props.obj.encrypt
                  ? t("Answer encryped")
                  : t("Answer not encryped")
              }
              className="mt-2 mb-0"
            />
          </Grid>
        )}
        {(hasURL || isYouTube) && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={isYouTube ? t("YouTube ID") : t("Media URL")}
              type="text"
              name="url"
              variant="filled"
              value={props.obj.url ? props.obj.url : ""}
              color="secondary"
              className="mt-2 answer-text-green"
              onChange={(e) =>
                e.target.value === ""
                  ? handleSetObj(e.target.name, null)
                  : handleSetObj(e.target.name, e.target.value)
              }
            />
          </Grid>
        )}
        {hasFormLabel && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("Form label")}
              type="text"
              name="formLabel"
              variant="filled"
              value={props.obj.formLabel ? props.obj.formLabel : ""}
              color="secondary"
              className="mt-2 answer-text-green"
              onChange={(e) =>
                // Must be a String to enable the tranlate toolbox
                handleSetObj(e.target.name, e.target.value)
              }
            />
          </Grid>
        )}
        {hasInputLabel && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("Placeholder input label")}
              type="text"
              name="inputLabel"
              variant="filled"
              value={props.obj.inputLabel ? props.obj.inputLabel : ""}
              color="secondary"
              className="mt-2 answer-text-green"
              onChange={(e) =>
                // Must be a String to enable the tranlate toolbox
                handleSetObj(e.target.name, e.target.value)
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleSettings;
