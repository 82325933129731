// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// Support functions
import { findAndUpdateNestedObject } from "../../supportFunc/findNestedObject";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/surveys`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  translateSurvey: null,
  status: "idle", // 'idle' | 'loading' | 'partial' | 'succeeded' | 'failed',
  maxStatus: "idle",
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all surveys
export const fetchSurveyList = createAsyncThunk(
  "surveys/fetchSurveyList",
  async ({ requestingUser }) => {
    let response;
    response = await client.get(apiurl, requestingUser);
    return response.data;
  },
);
// -------------------------------------------------
// API fetch a survey by its id
export const fetchSurveyById = createAsyncThunk(
  "surveys/fetchSurveyById",
  async ({ requestingUser, surveyId, upsert }, thunk) => {
    const state = thunk.getState(); // eslint-disable-line no-unused-vars
    // If not upsert and survey id does not exist, then retun null
    // TODO: Check whether && !state.surveys.entities[surveyId] can be removed below
    if (!upsert) {
      return { survey: null };
    }
    const response = await client.get(`${apiurl}/${surveyId}`, requestingUser);
    return response.data;
  },
);

// -------------------------------------------------
// API fetch a survey translations by its id
export const fetchSurveyTranslationsById = createAsyncThunk(
  "surveys/fetchSurveyTranslationsById",
  async ({ requestingUser, surveyId, upsert }, thunk) => {
    const state = thunk.getState();
    // If not upsert and survey id does not exist, then retun null
    if (!upsert && !state.surveys.entities[surveyId]) {
      return { survey: null };
    }
    const response = await client.get(
      `${apiurl}/all-languages/${surveyId}`,
      requestingUser,
    );
    return response.data;
  },
);
// -------------------------------------------------
// API patch a survey object
export const patchCurrentSurvey = createAsyncThunk(
  "surveys/patchCurrentSurvey",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body,
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-survey", { surveyId: body.data._id });
    // Return the response
    return response.data;
  },
);
// -------------------------------------------------
// API delete a survey object
export const deleteCurrentSurvey = createAsyncThunk(
  "surveys/deleteCurrentSurvey",
  async ({ socket, requestingUser, surveyId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${surveyId}`,
      requestingUser,
    );
    // Invoke event on server
    socket && socket.emit("deleted-current-survey", { surveyId });
    // Return the response
    return response.data;
  },
);
// =================================================
// DEFINE MUTATING ACTIONS
export const surveySlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    mutateSurveyByObjId(state, action) {
      const { surveyId, targetId, update } = action.payload;
      if (state.entities[surveyId]) {
        // Replace the existing survey with the updated one
        state.entities[surveyId] = findAndUpdateNestedObject(
          state.entities[surveyId],
          targetId,
          update,
        );
      }
    },
    resetSurveysError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.maxStatus = "idle";
        state.errorMsg = null;
      }
    },
    setSurveysStatus(state, action) {
      state.status = action.payload.status;
    },
    removeSurveyById(state, action) {
      adapter.removeOne(state, action.payload.surveyId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSurveyList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSurveyList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maxStatus = "succeeded";
        if (action.payload.surveyList) {
          action.payload.surveyList.forEach((survey) => {
            adapter.upsertOne(state, survey);
          });
        }
      })
      .addCase(fetchSurveyList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchSurveyById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSurveyById.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.survey &&
          adapter.upsertOne(state, action.payload.survey);
      })
      .addCase(fetchSurveyById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchSurveyTranslationsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSurveyTranslationsById.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        state.translateSurvey = action.payload.survey
          ? action.payload.survey
          : null;
      })
      .addCase(fetchSurveyTranslationsById.rejected, (state, action) => {
        state.translateSurvey = null;
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentSurvey.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentSurvey.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.survey &&
          adapter.upsertOne(state, action.payload.survey);
      })
      .addCase(patchCurrentSurvey.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentSurvey.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentSurvey.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.surveyId &&
          adapter.removeOne(state, action.payload.surveyId);
      })
      .addCase(deleteCurrentSurvey.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  setSurveysStatus,
  removeSurveyById,
  resetSurveysError,
  mutateSurveyByObjId,
} = surveySlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const surveysSelectors = adapter.getSelectors((state) => state.surveys);
// =================================================
// EXPORT DEFAULT
export default surveySlice.reducer;
