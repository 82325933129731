// ########################################
// IMPORT
// ========================================
// Dependencies
import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// ----------------------------------------
// Stylesheets (ASR/NSR CSS files are conditionally imported below).
import "bootstrap/dist/js/bootstrap.bundle.min";
// ----------------------------------------
// Routing
import PrivateRoute from "./PrivateRoute";
// ----------------------------------------
// Pages
import AuthSignOut from "./pages/AuthSignOut";
import AuthSignIn from "./pages/AuthSignIn";
import AuthVerify from "./pages/AuthVerify";
import AuthRegister from "./pages/AuthRegister";
import AuthPermissions from "./pages/AuthPermissions";
import AuthUserManagement from "./pages/AuthUserManagement";
import Tasks from "./pages/Tasks";
import Studies from "./pages/Studies";
import Surveys from "./pages/Surveys";
import Results from "./pages/Results";
import Volunteers from "./pages/Volunteers";
import Investigators from "./pages/Investigators";
import Messages from "./pages/Messages";
import Account from "./pages/Account";
import Welcome from "./pages/Welcome";
import CMSEditor from "./pages/CMSEditor";
import Webpage from "./pages/Webpage";
import DashboardCBTI from "./pages/DashboardCBTI";
// ----------------------------------------
// Components
import AppScrollToTop from "./components/App_ScrollToTop";
import SetCurrentPage from "./components/App_SetCurrentPage";
import AlertMessage from "./components/App_AlertMessage";
// ----------------------------------------
// Redux
import { fetchWebpageList } from "./redux/reducers/publicWebpages";
import { toggleNav, setScreenSize } from "./redux/reducers/ui";
// ----------------------------------------
// Support functions
import { debounce } from "./supportFunc/debounce";
// ========================================
// Material UI
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// ----------------------------------------
// Set CSS, font and colors according to locale
if (process.env.REACT_APP_TITLE === "Australian Sleep Registry") {
  import("./App_ASR.css");
} else {
  import("./App_NSR.css");
}

let palettePrimary,
  paletteSecondary,
  fontFamily,
  buttonFontFamily,
  headerFontFamily;
if (process.env.REACT_APP_TITLE === "Australian Sleep Registry") {
  palettePrimary = "rgb(138, 40, 126)";
  paletteSecondary = "rgb(24, 155, 135)";
  // fontFamily = "Fira Sans";
  // buttonFontFamily = "Fira Sans";
  // headerFontFamily = "Barlow Condensed";
  headerFontFamily = "Kreon";
  fontFamily = "Open Sans";
  buttonFontFamily = "Open Sans";
} else {
  palettePrimary = "rgb(229, 209, 138)";
  paletteSecondary = "rgb(170, 146, 110)";
  fontFamily = "Didact Gothic";
  buttonFontFamily = "Jost";
  headerFontFamily = "Jost";
}

// ----------------------------------------
// Material UI Theme
const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // MOBILE 0-675
      sm: 676, // TABLET 676 - 960
      md: 961, // DISPLAY MD 961 - 1280
      lg: 1281, // DISPLAY LG 1281-1920
      xl: 1921, // DISPLAY XL > 1920
    },
  },
  palette: {
    primary: {
      main: palettePrimary,
    },
    secondary: {
      main: paletteSecondary,
    },
  },
  typography: {
    fontFamily,
    button: {
      fontFamily: buttonFontFamily,
    },
    subtitle1: { fontSize: "1rem", fontWeight: "bold" },
    subtitle2: { fontSize: "1rem", fontWeight: "bold", fontStyle: "italic" },
    h1: {
      fontFamily: headerFontFamily,
      fontSize: "1.9rem",
      fontWeight: "400",
    },
    h2: {
      fontFamily: headerFontFamily,
      fontSize: "1.75rem",
      fontWeight: "400",
    },
    h3: {
      fontFamily: headerFontFamily,
      fontSize: "1.60rem",
      fontWeight: "400",
    },
    h4: {
      fontFamily: headerFontFamily,
      fontSize: "1.45rem",
      fontWeight: "400",
    },
    h5: {
      fontFamily: headerFontFamily,
      fontSize: "1.30rem",
      fontWeight: "400",
    },
    h6: {
      fontFamily: headerFontFamily,
      fontSize: "1.15rem",
      fontWeight: "400",
    },
  },
  props: {
    MuiTooltip: { arrow: true },
  },
  components: {
    // Disable the enforce focus for modals to fix issue with TinyMCE source code dialog in CMSEditor
    MuiModal: {
      defaultProps: {
        disableEnforceFocus: true,
      },
    },
  },
});

// ########################################
// FUNCTIONAL COMPONENT
const App = () => {
  // ========================================
  // FUNCTIONS
  const dispatch = useDispatch();
  // ----------------------------------------
  // When window resizes, close nav, and set size booleans
  const handleResize = debounce(() => {
    // Close the navigation
    dispatch(toggleNav({ isOpen: false }));
    // Set the new screen sizes
    dispatch(setScreenSize());
  }, 30);
  const webpagesStatus = useSelector((state) => state.publicWebpages.status);
  // -----------------------------------------------
  // Use effect function to load webpages
  useEffect(() => {
    if (webpagesStatus === "idle") {
      dispatch(fetchWebpageList());
    }
  }, [dispatch, webpagesStatus]); // Add event listener once upon mounting
  // -----------------------------------------------
  // Use effect function to listen to window resize events
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Clean-up the event listener upon unmounting
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]); // Add event listener once upon mounting
  // ========================================
  // VARIABLES
  // ========================================
  // RETURN
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading... </div>}>
        <AppScrollToTop />
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <AlertMessage />
          <SetCurrentPage />
          <Routes>
            <Route path="/" element={<PrivateRoute />} />
            <Route
              path="/welcome"
              element={
                <PrivateRoute>
                  <Welcome />
                </PrivateRoute>
              }
            />
            <Route
              path="/studies"
              element={
                <PrivateRoute>
                  <Studies />
                </PrivateRoute>
              }
            />
            <Route
              path="/surveys"
              element={
                <PrivateRoute>
                  <Surveys />
                </PrivateRoute>
              }
            />
            <Route
              path="/results"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="/volunteers"
              element={
                <PrivateRoute>
                  <Volunteers />
                </PrivateRoute>
              }
            />
            <Route
              path="/investigators"
              element={
                <PrivateRoute>
                  <Investigators />
                </PrivateRoute>
              }
            />
            <Route
              path="/tasks"
              element={
                <PrivateRoute>
                  <Tasks />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              path="/permissions"
              element={
                <PrivateRoute>
                  <AuthPermissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/cmseditor"
              element={
                <PrivateRoute>
                  <CMSEditor />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard-cbti"
              element={
                <PrivateRoute>
                  <DashboardCBTI />
                </PrivateRoute>
              }
            />

            <Route path="/register" element={<AuthRegister />} />
            <Route path="/sign-in" element={<AuthSignIn />} />
            <Route path="/sign-out" element={<AuthSignOut />} />
            <Route path="/ask-to-verify-email" element={<AuthVerify />} />
            <Route path="/user-management" element={<AuthUserManagement />} />
            {/* ... other routes ... */}
            <Route path="/:url" element={<Webpage />} />
          </Routes>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  );
};
// ########################################
// EXPORT
export default App;
