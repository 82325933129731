// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditOptions = (props) => {
  const { t } = useTranslation("surveys", {
    keyPrefix: "surveys.Surveys_EditOptions",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return props.options ? (
    props.options.map((option, i) => (
      <div key={`surveys_edit-options-${i}`}>
        {option.type === "text" ? (
          <TextField
            fullWidth
            label={option.label}
            size="small"
            type="text"
            name={option.key}
            variant="filled"
            value={
              props.obj && props.obj[option.key]
                ? props.obj[option.key]
                : option.default
            }
            color="secondary"
            className={i === 0 ? "answer-text-green" : "answer-text-green mt-3"}
            onChange={(e) =>
              // Must be a String to enable the tranlate toolbox
              handleSetObj(e.target.name, e.target.value)
            }
          />
        ) : option.type === "number" ? (
          <TextField
            fullWidth
            label={option.label}
            size="small"
            type="number"
            name={option.key}
            variant="filled"
            value={
              props.obj && props.obj[option.key]
                ? props.obj[option.key]
                : option.default
            }
            color="secondary"
            className={i === 0 ? "answer-text-green" : "answer-text-green mt-3"}
            onChange={(e) =>
              e.target.value === ""
                ? handleSetObj(e.target.name, option.default)
                : handleSetObj(e.target.name, parseInt(e.target.value))
            }
            onWheel={(e) => {
              if (document.activeElement === e.target) {
                e.target.blur(); // Blur the input to stop unwanted changes
                setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
              }
            }}
          />
        ) : option.type === "toggle" ? (
          <FormControlLabel
            label={option.label}
            control={
              <Switch
                checked={
                  props.obj && props.obj[option.key] !== undefined
                    ? props.obj[option.key]
                    : option.default
                }
                color="secondary"
                onChange={(e) => handleSetObj(option.key, e.target.checked)}
              />
            }
            className={i === 0 ? "w-100 mb-0" : "w-100 mb-0 mt-3"}
          />
        ) : option.type === "dropdown-menu" ? (
          <FormControl
            fullWidth
            variant="filled"
            color="secondary"
            className={i === 0 ? "" : "mt-3"}
          >
            <InputLabel id={`survey-option-${i}_input-label`} color="secondary">
              {option.label}
            </InputLabel>
            <Select
              labelId={`survey-option-${i}_input-label`}
              label={option.label}
              name={option.key}
              value={
                props.obj && props.obj[option.key]
                  ? props.obj[option.key]
                  : option.default
              }
              className="answer-text-green"
              color="secondary"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            >
              {option.optionList.map((a, j) => (
                <MenuItem
                  key={`survey-option-${i}_answer-${j}`}
                  value={a.value}
                >
                  {a.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : option.type === "date" ? (
          <MobileDatePicker
            label={option.label}
            value={
              props.obj && props.obj[option.key]
                ? DateTime.fromISO(props.obj[option.key])
                : DateTime.fromISO(option.default)
            }
            format={"yyyy-MM-dd"}
            slotProps={{
              actionBar: {
                actions: ["today"],
              },
              toolbar: false,
              textField: {
                fullWidth: true,
                variant: "filled",
                color: "secondary",
                className:
                  i === 0 ? "answer-text-green" : "answer-text-green mt-3",
              },
            }}
            onChange={(value) => handleSetObj(option.key, value.toISODate())}
          />
        ) : (
          <p>{`Option '${option.type}' is not supported`}</p>
        )}
      </div>
    ))
  ) : (
    <Typography variant="h5" align="center">
      {t("No options available")}
    </Typography>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditOptions;
