// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import Close from "@mui/icons-material/Close";
import DeleteForever from "@mui/icons-material/DeleteForever";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditScoreArithmetic = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditScoreArithmetic",
  });
  // ===============================================
  // VARIABLES
  const [isTime, setIsTime] = useState(
    props.obj[0].aliasId === "constant"
      ? false
      : props.aliasList[props.obj[0].aliasId].type === "time",
  );
  const [isDuration, setIsDuration] = useState(
    props.obj[0].aliasId === "constant"
      ? false
      : props.aliasList[props.obj[0].aliasId].type === "duration",
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (i, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[i][key] = value;
      newState[i].values =
        newState[i].aliasId === "constant" && key === "values"
          ? value
          : newState[i].aliasId === "constant" && key !== "values"
            ? newState[i].values
            : newState[i].aliasId !== "constant" &&
                props.aliasList[newState[i].aliasId].answerList
              ? props.aliasList[newState[i].aliasId].answerList.reduce(
                  (result, answer) => {
                    return { ...result, [answer.value]: answer.value };
                  },
                  {},
                )
              : null;
      return newState;
    });
  };
  const handleSetObjItem = (e, i) => {
    if (e.target.value === "constant") {
      handleSetObj(i, "aliasId", "constant");
      handleSetObj(i, "pageId", "constant");
      handleSetObj(i, "gridContainerId", "constant");
      handleSetObj(i, "gridItemId", "constant");
      handleSetObj(i, "articleId", "constant");
      handleSetObj(i, "rowId", "constant");
      handleSetObj(i, "values", 1);
      handleSetObj(i, "type", "number");
      setIsTime(false);
      setIsDuration(false);
    } else {
      if (
        i === 0 &&
        props.obj.length > 1 &&
        !isDuration &&
        props.aliasList[e.target.value].type === "duration"
      ) {
        props.setObj((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          return [newState[0]];
        });
      }
      if (i === 0) {
        setIsTime(props.aliasList[e.target.value].type === "time");
        setIsDuration(props.aliasList[e.target.value].type === "duration");
      }
      handleSetObj(i, "type", props.aliasList[e.target.value].type);
      handleSetObj(i, "aliasId", e.target.value);
      handleSetObj(i, "pageId", props.aliasList[e.target.value].pageId);
      handleSetObj(
        i,
        "gridContainerId",
        props.aliasList[e.target.value].gridContainerId,
      );
      handleSetObj(i, "gridItemId", props.aliasList[e.target.value].gridItemId);
      handleSetObj(i, "articleId", props.aliasList[e.target.value].articleId);
      handleSetObj(i, "rowId", props.aliasList[e.target.value].rowId);
    }
  };
  const handleSetObjValues = (i, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[i].values[key] = value;
      return newState;
    });
  };
  const handleAddItem = (idx, operator) => {
    const firstAliasId = Object.keys(props.aliasList).filter((key) =>
      isTime && operator === "add"
        ? props.aliasList[key].type === "duration"
        : isTime && operator === "subtract"
          ? props.aliasList[key].type === "time" ||
            props.aliasList[key].type === "duration"
          : props.aliasList[key].type !== "time",
    );
    if (!firstAliasId || firstAliasId.length === 0) {
      return;
    }
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(idx + 1, 0, {
        aliasId: firstAliasId[0],
        pageId: props.aliasList[firstAliasId[0]].pageId,
        gridContainerId: props.aliasList[firstAliasId[0]].gridContainerId,
        gridItemId: props.aliasList[firstAliasId[0]].gridItemId,
        articleId: props.aliasList[firstAliasId[0]].articleId,
        rowId: props.aliasList[firstAliasId[0]].rowId,
        weight: 1,
        operator: operator,
        type: props.aliasList[firstAliasId[0]].type,
        convertTo: "m",
        values: props.aliasList[firstAliasId[0]].answerList
          ? props.aliasList[firstAliasId[0]].answerList.reduce(
              (result, answer) => {
                return { ...result, [answer.value]: answer.value };
              },
              {},
            )
          : null,
      });
      return newState;
    });
  };
  const handleDeleteItem = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(idx, 1); // Remove the item
      newState[0].operator = null; // Make sure the first item does not have an operator
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      {isTime ? (
        <Alert severity="info" className="mb-2">
          <AlertTitle>
            <Typography fontWeight="bold">
              {t('You\'ve selected a "time" question type.')}
            </Typography>
          </AlertTitle>
          (1) Add a duration to a timepoint. NewTime = Time + Duration. <br />
          (2) Subtract a duration from a timepoint. NewTime = Time - Duration.
          <br />
          (3) Subtract two timepoints. Duration = EndTime - StartTime. <br />
          Note that 24 hours will be added to 'EndTime' if the result 'Duration'
          is negative.
          <br />
          For example, 7:00 - 23:00 becomes (7:00 + 24:00) - 23:00 = 8:00.
        </Alert>
      ) : (
        <Alert severity="info" className="mb-2">
          <AlertTitle>
            <Typography fontWeight="bold">
              {t(
                "Arithmetic is executed sequentially, disregarding normal order of operations.",
              )}
            </Typography>
          </AlertTitle>
          {t(
            "The arithmetic result of the first two elements is used to calculate the arithmetic with the third element, etc.",
          )}
        </Alert>
      )}
      <Typography variant="h4">{t("Arithmetic")}</Typography>
      <TransitionGroup>
        {props.obj.map((o, i) => (
          <Collapse key={`surveys_edit-score-item-${i}`}>
            <div>
              <Grid container className="py-3" alignItems="center">
                {o.aliasId !== "constant" && !isTime ? (
                  <>
                    <Grid item xs={2}>
                      <Card className="bg-light w-100 h-100 border border-secondary shadow-none">
                        <Grid container className="p-2">
                          {/* WEIGHT */}
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label={t("Weight")}
                              type="number"
                              name="weight"
                              variant="filled"
                              value={o.weight ? o.weight : ""}
                              size="small"
                              color="secondary"
                              className="answer-text-green"
                              onChange={(e) =>
                                handleSetObj(
                                  i,
                                  e.target.name,
                                  parseFloat(e.target.value),
                                )
                              }
                              onWheel={(e) => {
                                if (document.activeElement === e.target) {
                                  e.target.blur(); // Blur the input to stop unwanted changes
                                  setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={1} className="text-center">
                      x
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} />
                )}
                <Grid item xs={isTime ? 10 : 8}>
                  <Card className="bg-light w-100 border border-secondary shadow-none">
                    {/* SELECT ITEM */}
                    <Grid container className="p-2">
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant="filled"
                          color="secondary"
                          size="small"
                        >
                          <Select
                            autoWidth
                            hiddenLabel
                            name="aliasId"
                            value={o.aliasId}
                            className="answer-text-green"
                            color="secondary"
                            onChange={(e) => handleSetObjItem(e, i)}
                          >
                            {!(isTime && props.obj.length > 1) && (
                              <MenuItem value="constant">
                                <Chip
                                  label={
                                    <Typography
                                      variant="overline"
                                      color="textSecondary"
                                    >
                                      {t("Constant")}
                                    </Typography>
                                  }
                                  variant="outlined"
                                  size="small"
                                  className="me-2 bg-white"
                                />
                              </MenuItem>
                            )}
                            {props.aliasList &&
                              Object.keys(props.aliasList)
                                .filter(
                                  // The second element of any arithmetic with times can only be of type "time" or "duration"
                                  (key) =>
                                    isTime && props.obj.length > 1
                                      ? props.aliasList[key].type === "time" ||
                                        props.aliasList[key].type === "duration"
                                      : true,
                                )
                                .filter(
                                  // The second element of 'additive' arithmetic with times can only be of type "duration"
                                  (key) =>
                                    isTime &&
                                    props.obj.length > 1 &&
                                    props.obj[i].operator === "add"
                                      ? props.aliasList[key].type === "duration"
                                      : true,
                                )
                                .filter(
                                  // Any element of arithmetic of number items cannot be of type "time"
                                  (key) =>
                                    !isTime && props.obj.length > 1
                                      ? props.aliasList[key].type !== "time"
                                      : true,
                                )
                                .map((key) => (
                                  <MenuItem key={key} value={key}>
                                    <Chip
                                      label={
                                        <Typography
                                          variant="overline"
                                          color="textSecondary"
                                        >
                                          {props.aliasList[key].alias}
                                        </Typography>
                                      }
                                      size="small"
                                      className="me-2"
                                    />
                                    {props.aliasList[key].title}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* CONVERT TO */}
                      {o.type === "duration" &&
                        props.obj[0].type !== "time" && (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                {t("Convert to")}
                              </Typography>
                            </Grid>
                            <ToggleButtonGroup
                              value={o.convertTo}
                              exclusive
                              size="small"
                              onChange={(e) =>
                                handleSetObj(i, "convertTo", e.target.value)
                              }
                            >
                              <ToggleButton value="d">{t("Days")}</ToggleButton>
                              <ToggleButton value="h">
                                {t("Hours")}
                              </ToggleButton>
                              <ToggleButton value="m">
                                {t("Minutes")}
                              </ToggleButton>
                              <ToggleButton value="s">
                                {t("Seconds")}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        )}
                      {/* ANSWERS AND SCORES */}
                      <Grid item xs={12}>
                        {o.aliasId !== "constant" &&
                          props.aliasList[o.aliasId].answerList &&
                          (props.aliasList[o.aliasId].answerList.length !==
                            Object.keys(o.values).length ||
                            props.aliasList[o.aliasId].answerList.some(
                              (a) => !Object.keys(o.values).includes(a.value),
                            )) && (
                            <Alert severity="error" className="mt-1">
                              {t(
                                "Some score answer options do not match the question answer options!",
                              )}
                            </Alert>
                          )}
                        {o.aliasId === "constant" ? (
                          <TextField
                            fullWidth
                            hiddenLabel
                            type="number"
                            name="values"
                            variant="filled"
                            value={o.values ? o.values : ""}
                            size="small"
                            color="secondary"
                            className="answer-text-green mt-2"
                            onChange={(e) =>
                              handleSetObj(i, "values", e.target.value)
                            }
                            onWheel={(e) => {
                              if (document.activeElement === e.target) {
                                e.target.blur(); // Blur the input to stop unwanted changes
                                setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                              }
                            }}
                          />
                        ) : props.aliasList[o.aliasId].answerList ? (
                          <Grid container alignItems="center" className="mt-2">
                            <Grid item xs={9} className="pb-2">
                              <Typography variant="overline">
                                {t("Answer options")}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} className="pb-2">
                              <Typography variant="overline">
                                {t("Score")}
                              </Typography>
                            </Grid>
                            {props.aliasList[o.aliasId].answerList.map(
                              (answer, j) => (
                                <React.Fragment
                                  key={`surveys_edit-score_item-${i}_answer-${j}`}
                                >
                                  <Grid item xs={9} className="pb-2">
                                    <Grid container wrap="nowrap">
                                      <Grid item className="flex-grow-0">
                                        <Chip
                                          label={
                                            <Typography
                                              variant="overline"
                                              color="textSecondary"
                                            >
                                              {answer.value}
                                            </Typography>
                                          }
                                          variant={
                                            props.aliasList[o.aliasId]
                                              .reverseValue
                                              ? "outlined"
                                              : "filled"
                                          }
                                          size="small"
                                          className="me-2"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        className="pe-1 flex-grow-1"
                                        overflow="hidden"
                                      >
                                        <Typography className="text-truncate">
                                          {answer.text}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={3} className="pb-2">
                                    <TextField
                                      fullWidth
                                      hiddenLabel
                                      type="number"
                                      name="values"
                                      variant="filled"
                                      value={
                                        o.values[answer.value]
                                          ? o.values[answer.value]
                                          : "0"
                                      }
                                      size="small"
                                      color="secondary"
                                      className="answer-text-green"
                                      onChange={(e) =>
                                        e.target.value === ""
                                          ? handleSetObjValues(
                                              i,
                                              answer.value,
                                              "0",
                                            )
                                          : handleSetObjValues(
                                              i,
                                              answer.value,
                                              e.target.value,
                                            )
                                      }
                                      onWheel={(e) => {
                                        if (
                                          document.activeElement === e.target
                                        ) {
                                          e.target.blur(); // Blur the input to stop unwanted changes
                                          setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                                        }
                                      }}
                                    />
                                  </Grid>
                                </React.Fragment>
                              ),
                            )}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* MOVE AND DELETE BUTTONS */}
                <Grid item xs={1} align="right">
                  {props.obj.length > 1 && (
                    <Tooltip arrow title={t("Delete")} placement="right">
                      <span>
                        <IconButton onClick={(e) => handleDeleteItem(i)}>
                          <DeleteForever />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              {isTime && i > 0 ? null : (
                <Divider>
                  <ButtonGroup>
                    <Button
                      disabled={
                        isTime &&
                        props.obj[i + 1] &&
                        (props.aliasList[props.obj[i + 1].aliasId].type ===
                          "time" ||
                          Object.keys(props.aliasList).filter(
                            (key) => props.aliasList[key].type === "duration",
                          ).length === 0)
                      }
                      variant={
                        props.obj[i + 1] && props.obj[i + 1].operator === "add"
                          ? "contained"
                          : "outlined"
                      }
                      size="small"
                      color="inherit"
                      startIcon={<Add />}
                      className="border-dashed shadow-none"
                      onClick={() =>
                        i === props.obj.length - 1
                          ? handleAddItem(i, "add")
                          : handleSetObj(i + 1, "operator", "add")
                      }
                    >
                      {t("Add")}
                    </Button>
                    <Button
                      variant={
                        props.obj[i + 1] &&
                        props.obj[i + 1].operator === "subtract"
                          ? "contained"
                          : "outlined"
                      }
                      size="small"
                      color="inherit"
                      startIcon={<Remove />}
                      className="border-dashed shadow-none"
                      onClick={() =>
                        i === props.obj.length - 1
                          ? handleAddItem(i, "subtract")
                          : handleSetObj(i + 1, "operator", "subtract")
                      }
                    >
                      {t("Subtract")}
                    </Button>
                    {!isTime && (
                      <Button
                        variant={
                          props.obj[i + 1] &&
                          props.obj[i + 1].operator === "multiply"
                            ? "contained"
                            : "outlined"
                        }
                        size="small"
                        color="inherit"
                        startIcon={<Close />}
                        className="border-dashed shadow-none"
                        onClick={() =>
                          i === props.obj.length - 1
                            ? handleAddItem(i, "multiply")
                            : handleSetObj(i + 1, "operator", "multiply")
                        }
                      >
                        {t("Multiply")}
                      </Button>
                    )}
                    {!isTime && (
                      <Button
                        variant={
                          props.obj[i + 1] &&
                          props.obj[i + 1].operator === "divide"
                            ? "contained"
                            : "outlined"
                        }
                        size="small"
                        color="inherit"
                        startIcon="&#10135;"
                        className="border-dashed shadow-none"
                        onClick={() =>
                          i === props.obj.length - 1
                            ? handleAddItem(i, "divide")
                            : handleSetObj(i + 1, "operator", "divide")
                        }
                      >
                        {t("Divide")}
                      </Button>
                    )}
                  </ButtonGroup>
                </Divider>
              )}
            </div>
          </Collapse>
        ))}
      </TransitionGroup>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditScoreArithmetic;
