// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Helper functions
import {
  defaultValidation,
  defaultRowValidation,
} from "../../supportFunc/defaultSurveys";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditValidation = (props) => {
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value, i) => {
    props.setObj((prevState) => {
      let newState = [...prevState];
      newState[i] = { ...newState[i], [key]: value };
      if (key === "test" && value === null) {
        newState[i] = { ...newState[i], errorMsg: "" };
      }
      return newState;
    });
  };
  // ===============================================
  // Get the correct validation options
  const defValidation =
    props.articleType === "textbox" || props.articleType === "number"
      ? defaultValidation[props.articleType]
      : props.articleType === "textbox-list" ||
          props.articleType === "number-list" ||
          props.articleType === "textbox-grid" ||
          props.articleType === "number-grid"
        ? defaultRowValidation[props.articleType]
        : null;
  // ===============================================
  // RENDER COMPONENT
  return props.articleType === "textbox" ||
    props.articleType === "number" ||
    props.articleType === "textbox-list" ||
    props.articleType === "number-list" ||
    props.articleType === "textbox-grid" ||
    props.articleType === "number-grid" ? (
    <>
      {defValidation.map((validation, i) => (
        <React.Fragment key={`validation-${props.articleType$}-${i}`}>
          {i > 0 && <Divider className="my-3" />}
          <Typography variant="h4">{validation.label}</Typography>
          <TextField
            fullWidth
            hiddenLabel
            size="small"
            type={validation.type === "regexp" ? "text" : "number"}
            name="test"
            variant="filled"
            value={
              props.obj && props.obj[i] && props.obj[i].test
                ? props.obj[i].test
                : ""
            }
            color="secondary"
            className="answer-text-green mt-1"
            onChange={(e) =>
              e.target.value === ""
                ? handleSetObj(e.target.name, null, i)
                : handleSetObj(e.target.name, e.target.value, i)
            }
            onWheel={(e) => {
              if (document.activeElement === e.target) {
                e.target.blur(); // Blur the input to stop unwanted changes
                setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
              }
            }}
          />
          {props.obj && props.obj[i] && props.obj[i].test && (
            <>
              <Typography className="mt-2">
                Message to display when answer does not pass validation
              </Typography>
              <TextField
                fullWidth
                hiddenLabel
                size="small"
                type="text"
                name="errorMsg"
                variant="filled"
                value={
                  props.obj && props.obj[i] && props.obj[i].errorMsg
                    ? props.obj[i].errorMsg
                    : ""
                }
                color="secondary"
                className="answer-text-green mt-1"
                onChange={(e) => handleSetObj(e.target.name, e.target.value, i)}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  ) : (
    <Typography variant="h5" align="center">
      No validation available
    </Typography>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditValidation;
