// =================================================
// IMPORTS
// -------------------------------------------------
// React and MUI Components
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Contexts
import { useAuth } from "../contexts/auth";

import { Card, CardContent, Typography } from "@mui/material";

import { fetchSurveyById } from "../redux/reducers/surveys";

// Custom Components
import DashboardLineChart from "./dashboard-cbti/Dashboard_LineChart";
import SleepBarChart from "./dashboard-cbti/SleepBarChart";
import SurveyResults from "./dashboard-cbti/SurveyResults";

// =================================================
// COMPONENT
// -------------------------------------------------

const DashboardCBTIContent = React.memo(({ participantData }) => {
  const { currentAuth } = useAuth();
  const dispatch = useDispatch();

  const surveyEntities = useSelector((state) => state.surveys.entities);

  // Use optional chaining to safely access participantData properties
  const cbtiTasks = participantData?.cbtiTasks || []; // eslint-disable-line react-hooks/exhaustive-deps

  // Collect all required survey IDs
  const surveyIdsNeeded = cbtiTasks
    .map((task) => task.taskResponse?.surveyId)
    .filter(Boolean);

  // Fetch surveys when cbtiTasks change
  useEffect(() => {
    surveyIdsNeeded.forEach((surveyId) => {
      // Survyes are sometimes partially loaded, one field that is missing is aliasList
      if (!surveyEntities[surveyId].aliasList) {
        dispatch(
          fetchSurveyById({
            requestingUser: currentAuth,
            surveyId,
            upsert: true,
          }),
        );
      }
    });
  }, [surveyIdsNeeded, dispatch, currentAuth, surveyEntities]);

  // Check if all surveys are loaded
  const allSurveysLoaded = surveyIdsNeeded.every((id) => surveyEntities[id]);

  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------

  if (!allSurveysLoaded) {
    return <Typography>Loading surveys...</Typography>;
  }

  return (
    <div className="mb-2">
      <Card className="mb-2">
        <CardContent>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
              <SleepBarChart data={participantData} cbtiTasks={cbtiTasks} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <div className="row">
                {[
                  {
                    data: participantData?.sleepEfficiency ?? [],
                    payload: participantData?.sleepLogTasks ?? [],
                    alias: "CSD_SLP_EFF_TST_TIB",
                    title: "SE (%)",
                    options: { ticks: [0, 0.8, 1], unit: "%", domain: [0, 1] },
                  },
                  {
                    data: participantData?.totalSleepTime ?? [],
                    payload: participantData?.sleepLogTasks ?? [],
                    alias: "CSD_TOTAL_SLEEP_TIME",
                    title: "TST (min)",
                    options: {
                      ticks: [240, 300, 360, 420, 480, 540, 600],
                      unit: "min",
                      domain: [240, 600],
                    },
                  },
                  {
                    data: participantData?.sleepOnsetLatency ?? [],
                    payload: participantData?.sleepLogTasks ?? [],
                    alias: "CSD5",
                    title: "SOL (min)",
                    options: {
                      ticks: [5, 15, 30, 45, 60, 90, 120],
                      unit: "min",
                      domain: [-1, 120],
                    },
                  },
                  {
                    data: participantData?.wakeAfterSleepOnset ?? [],
                    payload: participantData?.sleepLogTasks ?? [],
                    alias: "CSD7",
                    title: "WASO (min)",
                    options: {
                      ticks: [5, 15, 30, 45, 60, 90, 120],
                      unit: "min",
                      domain: [-1, 120],
                    },
                  },
                  {
                    data: participantData?.sleepQuality ?? [],
                    payload: participantData?.sleepLogTasks ?? [],
                    alias: "CSD11",
                    title: "Sleep Quality",
                    options: {
                      ticks: [1, 2, 3, 4, 5],
                      unit: "",
                      domain: [0.5, 5.5],
                    },
                  },
                ].map((chart, index) => (
                  <div className="col-6 mb-2 p-0" key={index}>
                    <DashboardLineChart
                      data={chart.data}
                      payload={chart.payload}
                      alias={chart.alias}
                      title={chart.title}
                      options={chart.options}
                      cbtiTasks={cbtiTasks}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="mb-2">
        <CardContent>
          <Typography variant="h6">CBTI Responses</Typography>
          <SurveyResults
            cbtiTasks={cbtiTasks}
            surveyEntities={surveyEntities}
          />
        </CardContent>
      </Card>
    </div>
  );
});

// =================================================
// EXPORT COMPONENT
export default DashboardCBTIContent;
