// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Component elements
import SurveysEditArticleSettings from "./Surveys_EditArticleSettings";
import SurveysEditArticleTitle from "./Surveys_EditArticleTitle";
import SurveysEditArticleContent from "./Surveys_EditArticleContent";
import SurveysEditArticleInstructions from "./Surveys_EditArticleInstructions";
import SurveysEditArticleAnswers from "./Surveys_EditArticleAnswers";
import SurveyEditArticleRows from "./Surveys_EditArticleRows";
import SurveysEditArticleColumns from "./Surveys_EditArticleColumns";
import SurveysEditLogic from "./Surveys_EditLogic";
import SurveysEditValidation from "./Surveys_EditValidation";
import SurveysEditLayout from "./Surveys_EditLayout";
import SurveysEditOptions from "./Surveys_EditOptions";
// -------------------------------------------------
// Helper functions
import {
  defaultOptions,
  defaultAnswer,
  defaultRow,
  defaultColumn,
  defaultRowOptions,
  defaultValidation,
  defaultRowValidation,
} from "../../supportFunc/defaultSurveys";
// -------------------------------------------------
// Basic elements
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticle = ({
  obj,
  setObj,
  dependencies,
  scoring,
  aliasList,
}) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticle",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const hasAnswers =
    obj.type === "radio-vertical" ||
    obj.type === "radio-horizontal" ||
    obj.type === "radio-grid" ||
    obj.type === "dropdown-menu" ||
    obj.type === "grouped-dropdown-menu" ||
    obj.type === "dropdown-menu-list" ||
    obj.type === "dropdown-menu-grid";
  const hasTitle =
    obj.type !== "image-bento-box" &&
    obj.type !== "video-file" &&
    obj.type !== "video-youtube";
  const hasContent =
    obj.type !== "image" &&
    obj.type !== "image-bento-box" &&
    obj.type !== "video-file" &&
    obj.type !== "video-youtube";
  const hasInstructions =
    obj.type !== "image" &&
    obj.type !== "image-bento-box" &&
    obj.type !== "video-file" &&
    obj.type !== "video-youtube";
  const hasRows =
    obj.type === "image-bento-box" ||
    obj.type === "checkboxes" ||
    obj.type === "checkboxes-grid" ||
    obj.type === "radio-grid" ||
    obj.type === "dropdown-menu-list" ||
    obj.type === "dropdown-menu-grid" ||
    obj.type === "textbox-list" ||
    obj.type === "textbox-grid" ||
    obj.type === "number-list" ||
    obj.type === "number-grid" ||
    obj.type === "slider-horizontal";
  const hasColumns =
    obj.type === "checkboxes-grid" ||
    obj.type === "dropdown-menu-grid" ||
    obj.type === "textbox-grid" ||
    obj.type === "number-grid";
  const hasValidation = obj.type === "textbox" || obj.type === "number";
  const hasRowValidation =
    obj.type === "textbox-list" ||
    obj.type === "textbox-grid" ||
    obj.type === "number-list" ||
    obj.type === "number-grid";
  // -----------------------------------------------
  // Local state
  const [tabIdx, setTabIdx] = useState(0);
  const [title, setTitle] = useState(obj.title);
  const [content, setContent] = useState(obj.content);
  const [instructions, setInstructions] = useState(obj.instructions);
  const [answerList, setAnswerList] = useState();
  const [rowList, setRowList] = useState();
  const [columnList, setColumnList] = useState();
  const [logic, setLogic] = useState(obj.logic);
  const [layout, setLayout] = useState(obj.layout);
  const [options, setOptions] = useState(
    obj.options
      ? obj.options
      : defaultOptions[obj.type]
        ? defaultOptions[obj.type].reduce((obj, item) => {
            obj[item.key] = item.default;
            return obj;
          }, {})
        : null,
  );
  const [validation, setValidation] = useState(
    hasValidation && obj.validation
      ? obj.validation
      : hasValidation
        ? defaultValidation[obj.type].map((val) => {
            return {
              type: val.type,
              test: val.default,
              errorMsg: val.errorMsg,
            };
          })
        : null,
  );
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(
    () => {
      setObj((prevState) => {
        const newState = {
          ...prevState,
          title,
          content,
          instructions,
          answerList,
          rowList,
          columnList,
          logic,
          validation,
          layout,
          options,
        };
        return newState;
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      obj.type,
      title,
      content,
      instructions,
      answerList,
      rowList,
      columnList,
      logic,
      validation,
      layout,
      options,
    ],
  ); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // If the type changes to one with answers, items or columns, set those:
  useEffect(() => {
    setAnswerList(
      hasAnswers && obj.answerList
        ? obj.answerList
        : hasAnswers
          ? [defaultAnswer]
          : null,
    );
    setRowList(
      hasRows && obj.rowList
        ? obj.rowList.map((row) => {
            return {
              ...defaultRow,
              ...row,
              validation:
                hasRowValidation && row.validation
                  ? row.validation
                  : hasRowValidation
                    ? defaultRowValidation[obj.type].map((val, i) => {
                        return {
                          type: val.type,
                          test: val.default,
                          errorMsg: val.errorMsg,
                        };
                      })
                    : null,
              options: defaultRowOptions[obj.type],
            };
          })
        : hasRows && hasRowValidation
          ? [
              {
                ...defaultRow,
                validation: defaultRowValidation[obj.type].map((val, i) => {
                  return {
                    type: val.type,
                    test: val.default,
                    errorMsg: val.errorMsg,
                  };
                }),
                options: defaultRowOptions[obj.type],
                _id: uuid(),
              },
            ]
          : hasRows
            ? [
                {
                  ...defaultRow,
                  options: defaultRowOptions[obj.type],
                  _id: uuid(),
                },
              ]
            : null,
    );
    setColumnList(
      hasColumns && obj.columnList
        ? obj.columnList
        : hasColumns
          ? [{ ...defaultColumn, _id: uuid() }]
          : null,
    );
  }, [obj.type, hasAnswers, hasRows, hasColumns, hasRowValidation]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // If the type changes update the validation:
  useEffect(() => {
    setValidation(
      hasValidation && obj.validation
        ? obj.validation
        : hasValidation
          ? defaultValidation[obj.type].map((val) => {
              return {
                type: val.type,
                test: val.default,
                errorMsg: val.errorMsg,
              };
            })
          : null,
    );
  }, [obj.type]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box>
      {/* ----------------------------------------------- */}
      {/* TABS */}
      <Tabs
        variant="fullWidth"
        value={tabIdx}
        className="bg-white border-bottom border-light"
        onChange={(e, newTabIndex) => {
          setTabIdx(newTabIndex);
        }}
      >
        <Tab label={t("Article")} id="tab_article_settings" />
        <Tab
          label={
            obj.logic ? (
              <Badge color="info" variant="dot">
                {t("Logic")}&nbsp;&nbsp;
              </Badge>
            ) : (
              t("Logic")
            )
          }
          id="tab_article_logic"
        />
        <Tab
          disabled={!hasValidation}
          label={t("Validation")}
          id="tab_article_validation"
        />
        <Tab label={t("Layout")} id="tab_article_layout" />
        <Tab
          disabled={options === null}
          label={t("Options")}
          id="tab_article_options"
        />
      </Tabs>
      {/* ----------------------------------------------- */}
      {/* PAGE TITLE TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 0}
      >
        {/* ----------------------------------------------- */}
        {/* ARTICLE SETTINGS */}
        <SurveysEditArticleSettings
          obj={obj}
          setObj={setObj}
          setOptions={setOptions}
          articleId={obj._id}
          dependencies={dependencies}
          scoring={scoring}
        />
      </Paper>
      {(hasTitle || hasContent || hasInstructions) && (
        <Paper
          className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
          hidden={tabIdx !== 0}
        >
          {/* ----------------------------------------------- */}
          {/* ARTICLE TITLE */}
          {hasTitle && (
            <>
              <Typography variant="h4" className="mb-2">
                {obj.category === "media"
                  ? t("Title text")
                  : t("Question text")}
              </Typography>
              <SurveysEditArticleTitle
                showDivider={hasContent || hasInstructions}
                showOptions
                label={obj.category === "media" ? t("Title") : t("Question")}
                obj={title}
                setObj={setTitle}
              />
            </>
          )}
          {/* ----------------------------------------------- */}
          {/* ARTICLE CONTENT (SUBQUESTION) */}
          {hasContent && (
            <SurveysEditArticleContent
              alwaysEnable={obj.type === "text"}
              showOptions
              label={
                obj.category === "media"
                  ? t("Content text")
                  : t("Sub-question text")
              }
              obj={content}
              setObj={setContent}
            />
          )}
          {/* ----------------------------------------------- */}
          {/* ARTICLE INSTRUCTIONS */}
          {hasInstructions && (
            <SurveysEditArticleInstructions
              obj={instructions}
              setObj={setInstructions}
            />
          )}
        </Paper>
      )}
      {answerList && (
        <Paper
          className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
          hidden={tabIdx !== 0}
        >
          <SurveysEditArticleAnswers
            obj={answerList}
            setObj={setAnswerList}
            articleId={obj._id}
            dependencies={dependencies}
            scoring={scoring}
          />
        </Paper>
      )}
      {rowList && tabIdx === 0 && (
        <Paper
          className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
          hidden={tabIdx !== 0}
        >
          <SurveyEditArticleRows
            obj={rowList}
            setObj={setRowList}
            required={obj.required}
            articleId={obj._id}
            aliasList={
              aliasList &&
              Object.keys(aliasList)
                .filter(
                  (key) =>
                    aliasList[key].articleId !== obj._id &&
                    aliasList[key].type !== "duration" &&
                    aliasList[key].type !== "date-time" &&
                    aliasList[key].type !== "date" &&
                    aliasList[key].type !== "time",
                )
                .reduce((obj, key) => {
                  obj[key] = aliasList[key];
                  return obj;
                }, {})
            }
            dependencies={dependencies}
            scoring={scoring}
            type={obj.type}
          />
        </Paper>
      )}
      {columnList && (
        <Paper
          className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
          hidden={tabIdx !== 0}
        >
          <SurveysEditArticleColumns
            obj={columnList}
            setObj={setColumnList}
            aliasList={aliasList}
            dependencies={dependencies}
            scoring={scoring}
            hasInputLabel={obj.type !== "checkboxes-grid"}
          />
        </Paper>
      )}
      {/* ----------------------------------------------- */}
      {/* LOGIC TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 1}
      >
        <SurveysEditLogic
          obj={logic}
          setObj={setLogic}
          aliasList={
            aliasList &&
            Object.keys(aliasList)
              .filter(
                (key) =>
                  aliasList[key].articleId !== obj._id &&
                  aliasList[key].type !== "duration" &&
                  aliasList[key].type !== "date-time" &&
                  aliasList[key].type !== "date" &&
                  aliasList[key].type !== "time",
              )
              .reduce((obj, key) => {
                obj[key] = aliasList[key];
                return obj;
              }, {})
          }
        />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* VALIDATION TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 2}
      >
        <SurveysEditValidation
          articleType={obj.type}
          obj={validation}
          setObj={setValidation}
        />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* LAYOUT TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 3}
      >
        <SurveysEditLayout obj={layout} setObj={setLayout} />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* Options TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 4}
      >
        {options ? (
          <SurveysEditOptions
            obj={options}
            setObj={setOptions}
            options={defaultOptions[obj.type]}
          />
        ) : (
          <Typography variant="h5" align="center">
            {t("No options available")}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticle;
