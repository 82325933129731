// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import FormArticleQuestionSet from "./Form_ArticleQuestionSet";
// -------------------------------------------------
// Support functions
import { array2string } from "../supportFunc/array2string";
// -------------------------------------------------
// Redux actions
import { setSingleTaskResponseByAlias } from "../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../redux/reducers/previewResponses";
// -------------------------------------------------
// Media Types
import MediaText from "./form/MediaText";
import MediaImage from "./form/MediaImage";
import MediaImageBentoBox from "./form/MediaImageBentoBox";
import MediaVideoYouTube from "./form/MediaVideo_YouTube";
import MediaVideoFile from "./form/MediaVideo_File";
// -------------------------------------------------
// Question Types
import DropdownMenuItem from "./form/DropdownMenuItem";
import GroupedDropdownMenuItem from "./form/GroupedDropdownMenuItem";
import DropdownMenuList from "./form/DropdownMenuList";
import DropdownMenuGrid from "./form/DropdownMenuGrid";
import TextboxItem from "./form/TextboxItem";
import TextboxList from "./form/TextboxList";
import TextboxGrid from "./form/TextboxGrid";
import RadioItem from "./form/RadioItem";
import RadioGrid from "./form/RadioGrid";
import Checkboxes from "./form/Checkboxes";
import CheckboxesGrid from "./form/CheckboxesGrid";
import DurationItem from "./form/DurationItem";
import DurationItemMobile from "./form/DurationItemMobile";
import DateSelectorItem from "./form/DateSelectorItem";
import TimeSelectorItem from "./form/TimeSelectorItem";
import TimeSelectorItemMobile from "./form/TimeSelectorItemMobile";
import DateTimeSelectorItem from "./form/DateTimeSelectorItem";
import SliderHorizontalItem from "./form/SliderHorizontalItem";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/icons-material/Link";
import RuleIcon from "@mui/icons-material/Rule";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
// -------------------------------------------------
const FormArticle = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Form_Article" });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Redux, check if this article is enabled and in view
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  const viewIdx = useSelector((state) =>
    state.tickets.currentTicket ? state.tickets.currentTicket.viewIdx : null,
  );
  const responseCollection = useSelector((state) =>
    state.tickets.currentTicket
      ? state.tickets.currentTicket.responseCollection
      : null,
  );
  const responseId = useSelector((state) =>
    state.tickets.currentTicket ? state.tickets.currentTicket.responseId : null,
  );
  const isInView = useSelector((state) =>
    viewIdx !== null && state.form.views && state.form.views.length > 0
      ? state.form.views[viewIdx].articleIds.includes(props.article._id)
      : true,
  );
  // Get which items to show
  const rowId = useSelector((state) =>
    viewIdx !== null && state.form.views && state.form.views.length > 0
      ? state.form.views[viewIdx].rowId
      : null,
  );
  const doShow =
    props.doShow || (isEnabled && isInView) || (isEnabled && !isXS);
  // -----------------------------------------------
  // Generate the classes
  const classes = !doShow
    ? "form-article hidden-article "
    : props.article.category === "media" &&
        props.article.layout &&
        props.article.layout.classNames
      ? `form-article ${props.article.layout.classNames} ${props.className}`
      : props.article.logic &&
          props.article.layout &&
          props.article.layout.classNames
        ? `form-article conditional-article question-set ${props.article.layout.classNames} ${props.className}`
        : props.article.layout && props.article.layout.classNames
          ? `form-article question-set ${props.article.layout.classNames} ${props.className}`
          : `form-article question-set  ${props.className}`;
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get scored aliases
  const getScoredAliasList = () => {
    const aliasList =
      props.scoring &&
      (!props.article.rowList || props.article.type === "slider-horizontal") &&
      props.scoring.map(
        (score) =>
          (score.arithmetic &&
          score.arithmetic.some((a) => a.aliasId.includes(props.article._id))
            ? props.aliasList[score._id].alias
            : null) ||
          (score.grouping && score.grouping.aliasId.includes(props.article._id)
            ? props.aliasList[score._id].alias
            : null),
      );
    return array2string(aliasList);
  };
  // -----------------------------------------------
  // Handles setting a single response by its alias in the appropriate reducer
  const handleSetSingleResponse = (alias, value) => {
    switch (responseCollection) {
      case "taskResponses":
        dispatch(setSingleTaskResponseByAlias({ responseId, alias, value }));
        break;
      case "previewResponses":
        dispatch(setSinglePreviewResponseByAlias({ responseId, alias, value }));
        break;
      default:
        return;
    }
  };
  // -----------------------------------------------
  // When article is disabled, revert back to inital value(s)
  useEffect(() => {
    if (props.article.category === "question" && isEnabled === false) {
      props.article.columnList
        ? props.article.columnList.forEach((column) => {
            props.article.rowList.forEach((row) => {
              const alias = getAliasContainingId(
                props.aliasList,
                `${row._id}_${column._id}`,
              );
              handleSetSingleResponse(
                alias,
                props.article.type === "checkboxes-grid" ? false : null,
              );
            });
          })
        : props.article.rowList
          ? props.article.rowList.forEach((row) => {
              const alias = getAliasContainingId(props.aliasList, row._id);
              handleSetSingleResponse(
                alias,
                props.article.type === "checkboxes" ? false : null,
              );
            })
          : handleSetSingleResponse(
              getAliasContainingId(props.aliasList, props.article._id),
              null,
            );
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      <Grid
        item
        xs={12}
        className={`${classes} ${props.classNames}`}
        sx={
          props.article.layout && props.article.layout.minHeight
            ? {
                minHeight: `${props.article.layout.minHeight}px`,
              }
            : {}
        }
      >
        {props.children}
        {props.doShow &&
          props.aliasList &&
          props.aliasList[props.article._id] &&
          !props.article.rowList &&
          !props.article.columnList && (
            <Chip
              label={
                <Typography variant="overline" color="textSecondary">
                  {props.aliasList[props.article._id].alias}
                </Typography>
              }
              size="small"
            />
          )}
        {props.dependencies &&
          (!props.article.rowList ||
            props.article.type === "slider-horizontal") &&
          Object.keys(props.dependencies).some((dep) =>
            dep.includes(props.article._id),
          ) && (
            <Chip
              icon={<Link />}
              label={t("dependent")}
              size="small"
              variant="outlined"
              color="warning"
              className="border-white ms-2"
            />
          )}
        {props.doShow &&
          props.article.validation &&
          props.article.validation.some((val) => val.test) && (
            <Tooltip
              arrow
              title={
                <span>
                  {props.article.validation
                    .filter((val) => val.errorMsg)
                    .map((val, i) => (
                      <span
                        key={`article-${props.article._id}_validation-${i}`}
                        className="d-block"
                      >{`${val.errorMsg} `}</span>
                    ))}
                </span>
              }
              placement="top"
            >
              <RuleIcon color="inherit" className="ms-2" />
            </Tooltip>
          )}
        {props.scoring &&
          (!props.article.rowList ||
            props.article.type === "slider-horizontal") &&
          props.scoring.some(
            (score) =>
              (score.arithmetic &&
                score.arithmetic.some((a) =>
                  a.aliasId.includes(props.article._id),
                )) ||
              (score.grouping &&
                score.grouping.aliasId.includes(props.article._id)),
          ) && (
            <Chip
              icon={<Link />}
              label={t("scored in {{ scoredAliasList }}", {
                scoredAliasList: getScoredAliasList(),
              })}
              size="small"
              variant="outlined"
              color="success"
              className="border-white ms-2"
            />
          )}
        {props.doShow && props.article.logic && (
          <Chip
            icon={<Link />}
            label={`conditional on ${array2string(
              props.article.logic.map((logicGroup) =>
                logicGroup.itemList.map(
                  (logicItem) =>
                    props.aliasList[logicItem.condition.aliasId].alias,
                ),
              ),
            )}`}
            size="small"
            variant="outlined"
            color="info"
            className="border-white ms-2"
          />
        )}
        {doShow && props.article.category === "media" ? (
          <Fade in={true}>
            <div className="h-100">
              {props.article.type === "text" ? (
                <MediaText article={props.article} isLast={props.isLast} />
              ) : props.article.type === "image" ? (
                <MediaImage article={props.article} />
              ) : props.article.type === "image-bento-box" ? (
                <MediaImageBentoBox article={props.article} />
              ) : props.article.type === "video-file" ? (
                <MediaVideoFile article={props.article} />
              ) : props.article.type === "video-youtube" ? (
                <MediaVideoYouTube article={props.article} />
              ) : (
                <div>{`'${props.article.type}' is not supported`}</div>
              )}
              {props.article.layout &&
                !props.article.layout.noDivider &&
                !props.noDivider && <Divider />}
            </div>
          </Fade>
        ) : doShow && props.article.category === "question" ? (
          <Fade in={true}>
            <div
              className={
                !isXS && props.isLast && !props.noDivider
                  ? "h-100 pb-2 border-bottom"
                  : (isXS || props.isLast) && props.noDivider
                    ? "h-100"
                    : "h-100"
              }
            >
              <FormArticleQuestionSet
                noDivider={props.noDivider}
                article={props.article}
              />
              {props.article.type === "textbox" ? (
                <TextboxItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  type="text"
                  multiline={false}
                />
              ) : props.article.type === "textbox-long" ? (
                <TextboxItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  type="text"
                  multiline={true}
                />
              ) : props.article.type === "textbox-list" ? (
                <TextboxList
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  type="text"
                  row={false}
                />
              ) : props.article.type === "textbox-grid" ? (
                <TextboxGrid
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  type="text"
                  rowId={rowId}
                />
              ) : props.article.type === "number" ? (
                <TextboxItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  type="number"
                  multiline={false}
                />
              ) : props.article.type === "number-list" ? (
                <TextboxList
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  type="number"
                  row={false}
                />
              ) : props.article.type === "number-grid" ? (
                <TextboxGrid
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  type="number"
                  rowId={rowId}
                />
              ) : props.article.type === "radio-vertical" ? (
                <RadioItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  row={false}
                  article={props.article}
                  answerList={props.article.answerList}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "radio-horizontal" ? (
                <RadioItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  row={true}
                  article={props.article}
                  answerList={props.article.answerList}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                  forceRow={props.article.options.forceRow}
                />
              ) : props.article.type === "radio-grid" ? (
                <RadioGrid
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  rowId={rowId}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "checkboxes" ? (
                <Checkboxes
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  required={false}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "checkboxes-grid" ? (
                <CheckboxesGrid
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  rowId={rowId}
                />
              ) : props.article.type === "dropdown-menu" ? (
                <DropdownMenuItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  isLast={true}
                  article={props.article}
                  answerList={props.article.answerList}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "grouped-dropdown-menu" ? (
                <GroupedDropdownMenuItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  isLast={true}
                  article={props.article}
                  answerList={props.article.answerList}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "dropdown-menu-list" ? (
                <DropdownMenuList
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  rowId={rowId}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "dropdown-menu-grid" ? (
                <DropdownMenuGrid
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  aliasList={props.aliasList}
                  scoring={props.scoring}
                  article={props.article}
                  rowId={rowId}
                />
              ) : props.article.type === "date" ? (
                <DateSelectorItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "time" && !isXS ? (
                <TimeSelectorItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "time" && isXS ? (
                <TimeSelectorItemMobile
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "date-time" ? (
                <DateTimeSelectorItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "duration" && !isXS ? (
                <DurationItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "duration" && isXS ? (
                <DurationItemMobile
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                  )}
                />
              ) : props.article.type === "slider-horizontal" ? (
                <SliderHorizontalItem
                  doShow={props.doShow}
                  uneditable={props.uneditable}
                  dependencies={props.dependencies}
                  scoring={props.scoring}
                  article={props.article}
                  required={props.article.required}
                  alias={getAliasContainingId(
                    props.aliasList,
                    props.article._id,
                    true,
                  )}
                />
              ) : (
                `The article type '${props.article.type}' is not supported`
              )}
            </div>
          </Fade>
        ) : (
          <div className="p-3">{`The article type '${props.article.category}' is not supported`}</div>
        )}
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default FormArticle;
