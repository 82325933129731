// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Support functions
import { getSurveyViews } from "../supportFunc/getSurveyViews";
import { initSurveyResponses } from "../supportFunc/initSurveyResponses";
// -------------------------------------------------
// Redux actions
import { getTicketIsAboutToExpireById } from "../redux/reducers/tickets";
import {
  setFormTicketId,
  setViews,
  setFormStatus,
  initFormSurveyLogic,
} from "../redux/reducers/form";
import { setInitTaskResponses } from "../redux/reducers/taskResponses";
import { setInitPreviewResponses } from "../redux/reducers/previewResponses";
import { surveysSelectors, fetchSurveyById } from "../redux/reducers/surveys";
import { publicSurveysSelectors } from "../redux/reducers/publicSurveys";
import { setAlert, togglePrimDrawer } from "../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import FormPage from "./Form_Page";
import FormGridContainer from "./Form_GridContainer";
import FormGridItem from "./Form_GridItem";
import FormArticle from "./Form_Article";
import FormButtons from "./Form_Buttons";
// -------------------------------------------------
// Custom Survey components
import ConsensusSleepDiary from "./custom/ConsensusSleepDiary";
// =================================================
// FUNCTIONAL COMPONENT
const FormRoot = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Form_Root" });
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // -----------------------------------------------
  // context
  const { currentAuth } = useAuth();
  // -------------------------------------------------
  // Redux
  let currentSurvey;
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const formStatus = useSelector((state) => state.form.status);
  const surveyStatus = useSelector((state) => state.surveys.status);
  const publicSurveyStatus = useSelector((state) => state.publicSurveys.status);
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const thisSurvey = useSelector((state) =>
    surveysSelectors.selectById(state, currentTicket && currentTicket.surveyId),
  );
  const thisPublicSurvey = useSelector((state) =>
    publicSurveysSelectors.selectById(
      state,
      currentTicket && currentTicket.surveyId,
    ),
  );
  const isAboutToExpire = useSelector((state) =>
    getTicketIsAboutToExpireById(state, currentTicket && currentTicket._id),
  );
  const errorMsg = useSelector((state) => state.form.errorMsg);
  // =================================================
  if (thisSurvey) {
    currentSurvey = thisSurvey;
  } else if (thisPublicSurvey) {
    currentSurvey = thisPublicSurvey;
  } else {
    currentSurvey = null;
  }
  // =================================================
  // FUNCTIONS
  // Load the complete survey if it is not yet loaded
  useEffect(() => {
    if (currentSurvey.pageList) {
      return; // already loaded
    }
    dispatch(
      fetchSurveyById({
        requestingUser: currentAuth,
        surveyId: currentSurvey._id,
        upsert: true,
      }),
    );
  }, [currentSurvey._id]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Excute some stuff upon render, or when form status changes
  useEffect(() => {
    if (!currentTicket) {
      return;
    }
    // The form resets to 'idle' when a new ticket-id is grabbed from the URL
    if (formStatus === "idle" && currentSurvey.pageList) {
      // Calculate views
      const views = getSurveyViews(currentSurvey);
      // Set ticket Id, current survey and views
      dispatch(setFormTicketId({ ticketId: currentTicket._id }));
      dispatch(setViews({ views }));
      // Calculate initial responses (if exists from Redux i, otherwise from helper function)
      const initResponses = initSurveyResponses(currentTicket, currentSurvey);
      // Set initial responses in appropriate reducer
      switch (currentTicket.responseCollection) {
        case "taskResponses":
          dispatch(setInitTaskResponses({ initResponses }));
          break;
        case "previewResponses":
          dispatch(setInitPreviewResponses({ initResponses }));
          break;
        default:
          break;
      }
      // Set the form-survey logic
      dispatch(initFormSurveyLogic());
      // Set form status to "succeeded"
      dispatch(setFormStatus({ status: "succeeded" }));
    }
  }, [formStatus, currentTicket, currentSurvey]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // When ticket is about to expire, set warning message
  useEffect(() => {
    if (isAboutToExpire === "aboutToExpire") {
      dispatch(
        setAlert({
          type: "snackbar",
          variant: "warning",
          message: t(
            "Task is about to expire and will close autmatically within 2 minutes.",
          ),
          duration: DateTime.fromISO(currentTicket.dateExpire)
            .diffNow()
            .valueOf(),
        }),
      );
    }
  }, [isAboutToExpire]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Automatically close drawer and redirect to /tasks when ticket is completed
  useEffect(() => {
    let timeoutId;
    if (
      formStatus === "succeeded" &&
      currentTicket &&
      currentTicket.isCompleted &&
      !currentTicket.remainVisible &&
      currentTicket.responseCollection !== "previewResponses" &&
      ghostUser === null
    ) {
      timeoutId = setTimeout(() => {
        if (primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: false }));
        }
        navigate("/tasks", { replace: true });
      }, 5000);
    }
    // Clean-up upon unmounting
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [formStatus, currentTicket]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // RENDER COMPONENT
  // -------------------------------------------------
  // Something has gone horribly wrong
  if (formStatus === "failed") {
    return (
      <AppDrawerPlaceholder
        imageURL="images/icon-stranded.png"
        imageAlt={t("An unexpected error occurred")}
        title={errorMsg}
      />
    );
  }
  // Currently loading the tickets from database, or idling (triggers 'useEffect' above)
  if (
    formStatus === "idle" ||
    formStatus === "loading" ||
    surveyStatus === "loading" ||
    publicSurveyStatus === "loading" ||
    !currentSurvey.pageList
  ) {
    return (
      <AppDrawerPlaceholder
        imageURL="images/icon-wait.gif"
        imageAlt={t("Loading, please wait...")}
        title={t("Loading, please wait...")}
      />
    );
  }
  // This ticket is expired
  if (
    formStatus === "succeeded" &&
    currentTicket &&
    currentTicket.dateExpire &&
    isAboutToExpire === "isExpired" &&
    ghostUser === null
  ) {
    return (
      <AppDrawerPlaceholder
        imageURL="images/icon-expired.jpg"
        imageAlt={t("This task is expired")}
        caption={t("You've just missed the boat, sorry.")}
        title={t("This task is expired")}
      />
    );
  }
  // This ticket is completed
  if (
    formStatus === "succeeded" &&
    currentTicket &&
    currentTicket.isCompleted &&
    !currentTicket.remainVisible &&
    currentTicket.responseCollection !== "previewResponses" &&
    ghostUser === null
  ) {
    return (
      <AppDrawerPlaceholder
        imageURL="images/icon-hooray.svg"
        imageAlt={t("Thank you very much")}
        title={t("Thank you very much for your efforts!")}
      />
    );
  }
  return (
    <div className={isXS ? "mb-80px" : "mb-5"}>
      {currentSurvey.pageList &&
        currentSurvey.pageList.map((page) => (
          <FormPage
            key={`page-${page._id}`}
            page={page}
            aliasList={currentSurvey.aliasList}
          >
            {page.gridContainerList &&
              page.gridContainerList.map((gc, j) => (
                <FormGridContainer
                  key={`page-${page._id}_gc-${gc._id}}`}
                  gridContainer={gc}
                  index={j}
                  aliasList={currentSurvey.aliasList}
                >
                  {gc.gridItemList &&
                    gc.gridItemList.map((gi, k) => (
                      <FormGridItem
                        key={`page-${page._id}_gc-${gc._id}}_gi-${gi._id}`}
                        gridItem={gi}
                        index={k}
                        aliasList={currentSurvey.aliasList}
                      >
                        {gi.articleList &&
                          gi.articleList.map((article, m) => (
                            <FormArticle
                              key={`page-${page._id}_gc-${gc._id}}_gi-${gi._id}_article-${article._id}`}
                              article={article}
                              noDivider={article.layout.noDivider}
                              isLast={gi.articleList.length - 1 === m}
                              aliasList={currentSurvey.aliasList}
                              uneditable={
                                currentTicket.isCompleted &&
                                !currentTicket.remainEditable &&
                                !ghostUser
                              }
                            />
                          ))}
                      </FormGridItem>
                    ))}
                </FormGridContainer>
              ))}
          </FormPage>
        ))}
      {thisSurvey.acronym === "CSD" &&
        currentTicket.viewIdx > 0 &&
        currentTicket.viewIdx < 11 && <ConsensusSleepDiary />}
      <FormButtons currentSurvey={currentSurvey} />
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default FormRoot;
