// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Add from "@mui/icons-material/Add";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleAnswers = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleAnswers",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const hasDependencies =
    (props.dependencies &&
      Object.keys(props.dependencies).some((key) =>
        key.includes(props.articleId),
      )) ||
    (props.scoring &&
      props.scoring.some(
        (score) =>
          (score.arithmetic &&
            score.arithmetic.some((a) =>
              a.aliasId.includes(props.articleId),
            )) ||
          (score.grouping && score.grouping.aliasId.includes(props.articleId)),
      ));
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (idx, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx][key] = value;
      return newState;
    });
  };
  const handleAddAnswer = (obj) => {
    props.setObj((prevState) => {
      let newValue = Math.max(...prevState.map((a) => parseFloat(a.value))) + 1;
      let newGroup = prevState[prevState.length - 1].group
        ? prevState[prevState.length - 1].group
        : "";
      let append = {
        text: "",
        value: newValue.toString(),
        group: newGroup,
      };
      append = { ...append, ...obj };
      let newState = [...prevState, append];
      return newState;
    });
  };
  const handleMoveAnswer = (idx, direction) => {
    props.setObj((prevState) => {
      let newState = [...prevState];
      let element = newState[idx];
      newState.splice(idx, 1);
      newState.splice(idx + direction, 0, element);
      return newState;
    });
  };
  const handleDeleteAnswer = (idx) => {
    props.setObj((prevState) => {
      let newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddAnswer();
        }}
      >
        <Button type="submit" className="d-none" />
        <Grid container alignItems="center">
          <Grid item xs={12} className="mb-2">
            <Typography variant="h4">{t("Answer options")}</Typography>
          </Grid>
          <Grid item xs={4} className="pe-2 mb-2">
            <Typography variant="overline">{t("Answer label")}</Typography>
          </Grid>
          <Grid item xs={2} className="pe-2 mb-2">
            <Typography variant="overline">{t("Value")}</Typography>
          </Grid>
          <Grid item xs={3} className="pe-2 mb-2">
            <Typography variant="overline">{t("Group")}</Typography>
          </Grid>
          <Grid item xs={3} className="pe-2 mb-2" />
          {props.obj.map((o, i) => (
            <React.Fragment key={`answer-${i}`}>
              <Grid item xs={4} className="pe-2 mb-2">
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  type="text"
                  name="text"
                  variant="filled"
                  value={o.text}
                  color="secondary"
                  className="answer-text-green"
                  onPaste={(e) => {
                    e.preventDefault();
                    const clipdata = e.clipboardData || window.clipboardData;
                    const texts = clipdata
                      .getData("text")
                      .replace(/(\r\n|\n|\r)/gm, "[newline]")
                      .split("[newline]");
                    texts.forEach((t, j) => {
                      if (props.obj[i + j]) {
                        handleSetObj(i + j, "text", t);
                      } else {
                        handleAddAnswer({ text: t });
                      }
                    });
                  }}
                  onChange={(e) =>
                    handleSetObj(i, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={2} className="pe-2 mb-2">
                <TextField
                  fullWidth
                  hiddenLabel
                  disabled={hasDependencies}
                  size="small"
                  type="number"
                  name="value"
                  variant="filled"
                  value={o.value}
                  color="secondary"
                  className="answer-text-green"
                  onPaste={(e) => {
                    e.preventDefault();
                    const clipdata = e.clipboardData || window.clipboardData;
                    const values = clipdata
                      .getData("text")
                      .replace(/(\r\n|\n|\r)/gm, "[newline]")
                      .split("[newline]");
                    values.forEach((t, j) => {
                      if (props.obj[i + j]) {
                        handleSetObj(i + j, "value", t);
                      } else {
                        handleAddAnswer({ value: t });
                      }
                    });
                  }}
                  onChange={(e) =>
                    handleSetObj(i, e.target.name, e.target.value)
                  }
                  onWheel={(e) => {
                    if (document.activeElement === e.target) {
                      e.target.blur(); // Blur the input to stop unwanted changes
                      setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3} className="pe-2 mb-2">
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  type="text"
                  name="group"
                  variant="filled"
                  value={o.group ? o.group : ""}
                  color="secondary"
                  className="answer-text-green"
                  onPaste={(e) => {
                    e.preventDefault();
                    const clipdata = e.clipboardData || window.clipboardData;
                    const groupList = clipdata
                      .getData("text")
                      .replace(/(\r\n|\n|\r)/gm, "[newline]")
                      .split("[newline]");
                    groupList.forEach((t, j) => {
                      if (props.obj[i + j]) {
                        handleSetObj(i + j, "group", t);
                      } else {
                        handleAddAnswer({ group: t });
                      }
                    });
                  }}
                  onChange={(e) =>
                    handleSetObj(i, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3} className="mb-2">
                {props.obj.length > 1 && (
                  <span>
                    <IconButton
                      disabled={hasDependencies || i === 0}
                      className="me-2"
                      onClick={(e) => handleMoveAnswer(i, -1)}
                    >
                      <ArrowUpward />
                    </IconButton>
                  </span>
                )}
                {props.obj.length > 1 && (
                  <span>
                    <IconButton
                      disabled={hasDependencies || i === props.obj.length - 1}
                      className="me-2"
                      onClick={(e) => handleMoveAnswer(i, 1)}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </span>
                )}
                {props.obj.length > 1 && (
                  <Tooltip
                    arrow
                    title={hasDependencies ? t("Can't delete") : t("Delete")}
                    placement="right"
                  >
                    <span>
                      <IconButton
                        disabled={hasDependencies}
                        onClick={(e) => {
                          handleDeleteAnswer(i);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </React.Fragment>
          ))}
          {!hasDependencies && (
            <Grid item xs={12}>
              <Divider>
                <ButtonGroup>
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<Add />}
                    className="border-dashed"
                    onClick={() => handleAddAnswer()}
                  >
                    {t("Add answer")}
                  </Button>
                </ButtonGroup>
              </Divider>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleAnswers;
