// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { DateTime } from "luxon";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContentInformation = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Consumer information keys
  const infoKeys = [
    { key: "createdAt", label: "Created", isDate: true },
    { key: "lastSignInOn", label: "Last sign-in", isDate: true },
  ];
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <Card className="mb-3 px-3">
      <List disablePadding>
        {infoKeys.map(
          (item, i) =>
            props.currentConsumer[item.key] && (
              <ListItem divider={i < infoKeys.length - 1} key={item.key}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary={item.label.toUpperCase()}
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <Typography paragraph={false} className="d-inline">
                      {item.isDate
                        ? DateTime.fromISO(
                            props.currentConsumer[item.key],
                          ).toRelativeCalendar()
                        : props.currentConsumer[item.key]}
                    </Typography>
                    {item.isDate && (
                      <Typography
                        paragraph={false}
                        variant="caption"
                        color="GrayText"
                        className="ps-3"
                      >
                        {DateTime.fromISO(
                          props.currentConsumer[item.key],
                        ).toFormat("ccc d LLL yyyy HH:mm")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            ),
        )}
      </List>
    </Card>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContentInformation;
