// ===============================================
// SURVEY ALIASSES
const { alphabet } = require("./alphabet");
// -----------------------------------------------
const getAliasTitle = (article, row, column) => {
  // ---------------------------------------------
  // Init
  let questionText, rowText, columnText;
  // ---------------------------------------------
  // Crop question text
  questionText =
    article && article.title && article.title.text
      ? `${article.title.text.slice(0, 60)}${
          article.title.text.length > 60 ? "..." : ""
        }`
      : "<No question text>";
  // ---------------------------------------------
  // Set column and row text
  if (column !== null) {
    rowText = row && row.text ? `[${row.text.slice(0, 30)}]` : "";
    columnText = column && column.text ? `[${column.text.slice(0, 30)}]` : "";
  } else if (row !== null) {
    if (article.type === "slider-horizontal") {
      rowText = `[row ${row}]`;
    } else {
      rowText = row && row.text ? `[${row.text.slice(0, 30)}]` : "";
    }
    columnText = "";
  } else {
    rowText = "";
    columnText = "";
  }
  return `"${questionText}" ${rowText}${columnText}`;
};
// Resets all aliases
export const setSurveyAliasList = (survey) => {
  survey = JSON.parse(JSON.stringify(survey));
  // ---------------------------------------------
  // Checks
  // If the survey name is an object (not a string), then this is a translation object
  // and we can assume that the aliases are already correctly set.
  if (
    survey.name.official.en ||
    survey.name.official.nl ||
    survey.name.official.de
  ) {
    return survey;
  }
  // ---------------------------------------------
  // Generate aliases
  let thisAlias;
  let newAliasList = {};
  let cnt = 0;
  let nQuestionArticles =
    survey.pageList &&
    survey.pageList
      .map(
        (page) =>
          page.gridContainerList &&
          page.gridContainerList.map(
            (gc) =>
              gc.gridItemList &&
              gc.gridItemList.map(
                (gi) =>
                  gi.articleList &&
                  gi.articleList.filter((a) => a.category === "question")
                    .length,
              ),
          ),
      )
      .flat(4)
      .reduce((psum, o) => psum + o, 0);
  survey.pageList &&
    survey.pageList.forEach((page) => {
      page.gridContainerList &&
        page.gridContainerList.forEach((gc) => {
          gc.gridItemList &&
            gc.gridItemList.forEach((gi) => {
              gi.articleList &&
                gi.articleList.forEach((article) => {
                  if (article.category === "question") {
                    cnt++;
                    article.columnList
                      ? article.rowList.forEach((row, i) => {
                          thisAlias = `${survey.acronym}${cnt}${alphabet[i]}`;
                          article.columnList.forEach((column, c) => {
                            newAliasList[
                              `${article._id}_${row._id}_${column._id}`
                            ] = {
                              alias: `${thisAlias}_${c + 1}`,
                              pageId: page._id,
                              gridContainerId: gc._id,
                              gridItemId: gi._id,
                              articleId: article._id,
                              rowId: row._id,
                              type: article.type,
                              title: getAliasTitle(article, row, column),
                              reverseValue:
                                row.reverseValue !== undefined
                                  ? row.reverseValue
                                  : false,
                              answerList:
                                article.answerList && row.reverseValue
                                  ? article.answerList.map((answer, i) => ({
                                      text: answer.text,
                                      value:
                                        article.answerList[
                                          article.answerList.length - i - 1
                                        ].value,
                                      group: answer.group,
                                    }))
                                  : article.answerList,
                            };
                          });
                        })
                      : article.rowList
                        ? article.rowList.forEach((row, i) => {
                            if (nQuestionArticles === 1) {
                              if (i > 0) {
                                cnt++;
                              }
                              thisAlias = `${survey.acronym}${cnt}`;
                            } else {
                              thisAlias = `${survey.acronym}${cnt}${alphabet[i]}`;
                            }
                            newAliasList[[`${article._id}_${row._id}`]] = {
                              alias: thisAlias,
                              pageId: page._id,
                              gridContainerId: gc._id,
                              gridItemId: gi._id,
                              articleId: article._id,
                              rowId: row._id,
                              type: article.type,
                              title:
                                article.type === "slider-horizontal"
                                  ? getAliasTitle(article, i + 1, null)
                                  : getAliasTitle(article, row, null),
                              reverseValue:
                                row.reverseValue !== undefined
                                  ? row.reverseValue
                                  : false,
                              answerList:
                                article.answerList && row.reverseValue
                                  ? article.answerList.map((answer, i) => ({
                                      text: answer.text,
                                      value:
                                        article.answerList[
                                          article.answerList.length - i - 1
                                        ].value,
                                      group: answer.group,
                                    }))
                                  : article.answerList,
                            };
                          })
                        : (newAliasList[article._id] = {
                            alias: `${survey.acronym}${cnt}`,
                            pageId: page._id,
                            gridContainerId: gc._id,
                            gridItemId: gi._id,
                            articleId: article._id,
                            rowId: null,
                            type: article.type,
                            title: getAliasTitle(article, null, null),
                            reverseValue: false,
                            answerList: article.answerList,
                          });
                  }
                });
            });
        });
    });
  let type;
  survey.scoring &&
    survey.scoring.forEach((score, s) => {
      type =
        score.method === "classification-value"
          ? "number"
          : score.method === "classification-label"
            ? "textbox"
            : score.method === "arithmetic" &&
                score.arithmetic[0].type === "time" &&
                score.arithmetic[1].type === "duration"
              ? "time"
              : score.method === "arithmetic" &&
                  score.arithmetic[0].type === "time" &&
                  score.arithmetic[1].type === "time"
                ? "duration"
                : "number";
      newAliasList[score._id] = {
        alias: `${survey.acronym}_${score.alias}`,
        pageId: null,
        gridContainerId: null,
        gridItemId: null,
        articleId: score._id,
        rowId: null,
        scoreIdx: s,
        type,
        title: `${score.method}: ${type}`,
        reverseValue: false,
        answerList: null,
      };
    });
  if (Object.values(newAliasList).length === 0) {
    newAliasList = null;
  }
  survey.aliasList = newAliasList;
  return survey;
};
