import React, { useState, useCallback, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemButton,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const AnswerOption = ({ answer }) => {
  const answers = Array.isArray(answer) ? answer : [answer];

  return (
    <>
      {answers.map((ans, i) => {
        if (!ans) return <div key={i}>-</div>;

        // Checkbox-type answer
        if (ans.checked !== undefined) {
          return (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              {ans.checked ? (
                <CheckBoxIcon fontSize="small" />
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="small" />
              )}
              <span style={{ marginLeft: 8 }}>{ans.text ?? "-"}</span>
            </div>
          );
        }

        // Radio-type answer
        if (ans.radio !== undefined) {
          return (
            <div key={i}>
              {ans.text ? `${ans.text} :` : ""} {ans.radio}
            </div>
          );
        }

        // Fallback: Just text
        return <div key={i}>{ans.text ?? "-"}</div>;
      })}
    </>
  );
};

const SurveyResults = ({ cbtiTasks, surveyEntities }) => {
  const [openWeeks, setOpenWeeks] = useState({});

  const cbtiResponses = useMemo(() => {
    const responses = [];

    cbtiTasks
      .sort((a, b) => a.acronym.localeCompare(b.acronym))
      .forEach((task) => {
        const response = task.taskResponse;
        if (!(response && response.data)) return;

        const { surveyId, data } = response;
        const survey = surveyEntities[surveyId];
        if (!survey) return;

        const moduleNumber = task.acronym.replace("CBCTI", "");
        const isCompleted = task.isCompleted;
        const questionMap = {};

        Object.keys(data).forEach((alias) => {
          const aliasListItem = Object.values(survey.aliasList ?? {}).find(
            (item) => item.alias === alias,
          );
          if (!aliasListItem) return;

          const page = survey.pageList?.find(
            (p) => p._id === aliasListItem.pageId,
          );
          const gridContainer = page?.gridContainerList?.find(
            (gc) => gc._id === aliasListItem.gridContainerId,
          );
          const gridItem = gridContainer?.gridItemList?.find(
            (gi) => gi._id === aliasListItem.gridItemId,
          );
          const article = gridItem?.articleList?.find(
            (a) => a._id === aliasListItem.articleId,
          );
          const questionText = article?.title?.text;
          if (!questionText) return;

          const answerValue = data[alias];

          // Build formatted answer object
          let formattedAnswer;
          if (aliasListItem.rowId) {
            // The row for checkbox/radio items
            const row = article.rowList?.find(
              (r) => r._id === aliasListItem.rowId,
            );
            if (row) {
              const isTextboxList = article.type?.includes("textbox-list");
              const rowText =
                isTextboxList && row.text
                  ? `${row.text}: ${answerValue}`
                  : row.text;
              const isRadio = article.type?.includes("radio");
              const radioText = isRadio
                ? article.answerList?.find((ans) => ans.value === answerValue)
                    ?.text
                : undefined;
              let maxValue = article.answerList?.map((ans) =>
                parseFloat(ans.value),
              );
              maxValue = maxValue ? Math.max(...maxValue) : null;
              formattedAnswer = {
                text: isTextboxList ? rowText : row.text,
                checked:
                  typeof answerValue === "boolean" ? answerValue : undefined,
                radio:
                  isRadio && radioText
                    ? `${radioText} (${answerValue}/${maxValue})`
                    : undefined,
              };
            }
          } else {
            // Non-row-based answers
            const isRadio = article.type?.includes("radio");
            const radioText = isRadio
              ? article.answerList?.find((ans) => ans.value === answerValue)
                  ?.text
              : undefined;
            let maxValue = article.answerList?.map((ans) =>
              parseFloat(ans.value),
            );
            maxValue = maxValue ? Math.max(...maxValue) : null;
            formattedAnswer = {
              text: !isRadio ? answerValue : undefined,
              checked:
                typeof answerValue === "boolean" ? answerValue : undefined,
              radio:
                isRadio && radioText
                  ? `${radioText} (${answerValue}/${maxValue})`
                  : undefined,
            };
          }

          if (!questionMap[questionText]) {
            questionMap[questionText] = [];
          }
          questionMap[questionText].push(formattedAnswer);
        });

        const questions = Object.entries(questionMap).map(
          ([question, answers]) => ({
            question,
            answer: answers.length > 1 ? answers : answers[0],
          }),
        );

        responses.push({
          week: moduleNumber,
          questions,
          isCompleted,
        });
      });

    responses.sort((a, b) => a.week - b.week);
    return responses;
  }, [cbtiTasks, surveyEntities]);

  const handleToggleWeek = useCallback((week) => {
    setOpenWeeks((prev) => ({
      ...prev,
      [week]: !prev[week],
    }));
  }, []);

  return (
    <>
      {cbtiResponses.map((response) => (
        <React.Fragment key={response.week}>
          <ListItem id={`week-${response.week}`}>
            <ListItemButton onClick={() => handleToggleWeek(response.week)}>
              <ListItemText primary={`CBTI Survey Week ${response.week}`} />
              {openWeeks[response.week] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={openWeeks[response.week]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {response.questions.map((q, idx) => (
                <ListItem key={idx}>
                  <ListItemText
                    primary={q.question}
                    secondary={<AnswerOption answer={q.answer} />}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </>
  );
};

export default React.memo(SurveyResults);
