// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// =================================================
// FUNCTIONAL COMPONENT
const MediaVideoFile = (props) => {
  if (!props.article.url) {
    return null;
  }
  // ===============================================
  // RENDER COMPONENT
  return (
    <video
      crossOrigin="anonymous"
      width="100%"
      autoPlay={props.article.options.autoplay}
      controls={props.article.options.controls}
      loop={props.article.options.loop}
      className="rounded"
      sx={{
        minWidth: props.article.options.minWidth,
        minHeight: props.article.options.minHeight,
      }}
    >
      <source
        src={props.article.url}
        type={
          props.article.url.endsWith(".mov") ? "video/quicktime" : "video/mp4"
        }
      />
      Your browser does not support the video tag.
    </video>
  );
};
// =================================================
// EXPORT COMPONENT
export default MediaVideoFile;
