// =================================================
// EXPORT FUNCTION
// -------------------------------------------------
// Sets the identifiers of the items to show
export const calculateTaskProgress = (responses) => {
  if (!responses) {
    return 0;
  }
  const totalFields = Object.keys(responses.data).length;
  if (totalFields === 0) return 0; // Avoid division by zero if object is empty
  const nonNullFields = Object.values(responses.data).filter(
    (value) => value !== null,
  ).length;
  return (nonNullFields / totalFields) * 100;
};
