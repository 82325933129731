// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Components
import StudiesEditTimepointSurveys from "./Studies_EditTimepointSurveys";
import VolunteersContentMeasurementItem from "../volunteers/Volunteers_ContentMeasurementItem";
// -------------------------------------------------
// Support func
import { getCreateUpdateDeleteTicketList } from "../../supportFunc/getCreateUpdateDeleteTicketList";
// -------------------------------------------------
// Redux
import { ticketsSelectors } from "../../redux/reducers/tickets";
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";
// -------------------------------------------------
// Icons
import Icon from "@mui/material/Icon";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditTimepoint = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_EditTimepoint",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local State
  const [hoveredTicket, setHoveredTicket] = useState({});
  const [measurementList, setMeasurementList] = useState(
    props.obj.measurementList,
  );
  const [previewToPost, setPreviewToPost] = useState([]);
  const [previewToPatch, setPreviewToPatch] = useState([]);
  const [previewToDelete, setPreviewToDelete] = useState([]);
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const studiesStatus = useSelector((state) => state.studies.status);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  ); // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      value.length === 0
    ) {
      value = null;
    }
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState = { ...newState, [key]: value };
      if (!newState.label) {
        props.setHasErrors(true);
      } else {
        props.setHasErrors(false);
      }
      return newState;
    });
  };
  const handleSetObjStart = (key, value) => {
    props.setObj((prevState) => {
      // Deep copy current state to new object
      let newState = JSON.parse(JSON.stringify(prevState));
      // Check what we need to change
      if (key === "type") {
        // eslint-disable-next-line
        switch (value) {
          case "manual":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: null,
                delay: null,
              },
            };
            break;
          case "days":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: null,
                delay: 1,
              },
            };
            break;
          case "weekday":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: "Mon",
                delay: 1,
              },
            };
            break;
        }
      } else {
        newState = { ...newState, start: { ...newState.start, [key]: value } };
      }
      return newState;
    });
  };
  // -----------------------------------------------
  // When the timepoint is edited, checks if any ticket needs to be created/updated/deleted
  const previewNewTicketList = () => {
    const { ticketListToPost, ticketListToPatch, ticketListToDelete } =
      getCreateUpdateDeleteTicketList(
        props.currentStudy,
        props.obj,
        ticketList,
        props.enrolledConsumerList,
        props.recreateTickets.completed,
      );
    setPreviewToPost(ticketListToPost);
    setPreviewToPatch(ticketListToPatch);
    setPreviewToDelete(ticketListToDelete);
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetGroupIds = (e) => {
    const {
      target: { value },
    } = e;
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let newGroupIds = typeof value === "string" ? value.split(",") : value;
      if (newGroupIds.length === 0) {
        newGroupIds = null;
      }
      newState.groupIdList = newGroupIds;
      return newState;
    });
  };
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(() => {
    props.setObj((prevState) => ({
      ...prevState,
      measurementList,
    }));
  }, [measurementList]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // When the parent object changes, recalculate the tickets to show a preview of what will change
  useEffect(() => {
    previewNewTicketList();
  }, [props.obj, props.recreateTickets]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      {studiesStatus !== "loading" &&
        (previewToPost.length !== 0 ||
          previewToPatch.length !== 0 ||
          previewToDelete.length !== 0) && (
          <Alert
            severity="warning"
            className={isXS ? "m-2" : "m-2 fix-width-625px"}
          >
            <b>Warning.</b> At least one ticket will be affected by the changes
            you applied to this timepoint. Affected tickets will be reset,
            requiring participants to restart the task, and new response data
            will be generated. Any existing response data linked to the original
            tickets will be retained. Preview the changes to existing and new
            tickets below.
          </Alert>
        )}
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {t("Timepoint label")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              error={!props.obj.label}
              helperText={!props.obj.label ? "Please specify a label" : ""}
              type="text"
              name="label"
              variant="filled"
              value={props.obj.label ? props.obj.label : ""}
              color="secondary"
              className="mb-3 answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {props.obj.start.type === "manual"
                ? "Timepoint starts manually"
                : props.obj.start.type === "weekday"
                  ? `Timepoint starts on the ${props.ordinalSuffixOf(
                      props.obj.start.delay,
                    )} ${props.obj.start.dayOfTheWeek} since ${
                      props.idx === 0
                        ? "enrollment"
                        : "previous timepoint onset"
                    }`
                  : props.obj.start.delay === 1
                    ? `Timepoint starts on the same day as ${
                        props.idx === 0
                          ? "enrollment"
                          : "previous timepoint onset"
                      }`
                    : props.obj.start.delay === 2
                      ? `Timepoint starts on the day after ${
                          props.idx === 0
                            ? "enrollment"
                            : "previous timepoint onset"
                        }`
                      : `Timepoint starts on the ${props.ordinalSuffixOf(
                          props.obj.start.delay,
                        )} day since ${
                          props.idx === 0
                            ? "enrollment"
                            : "previous timepoint onset"
                        }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={props.obj.start.type}
              exclusive
              size="small"
              onChange={(e, newStartType) =>
                newStartType && handleSetObjStart("type", newStartType)
              }
            >
              <ToggleButton value="manual">{t("Manual")}</ToggleButton>
              <ToggleButton value="days">
                {props.idx === 0
                  ? t("Days since enrollment")
                  : t("previous timepoint onset")}
              </ToggleButton>
              <ToggleButton value="weekday">
                {t("Day of the week")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {(props.obj.start.type === "days" ||
            props.obj.start.type === "weekday") && (
            <Grid item xs={3} className="pt-2">
              <TextField
                hiddenLabel
                type="number"
                name="delay"
                variant="filled"
                size="small"
                value={props.obj.start.delay ? props.obj.start.delay : ""}
                color="secondary"
                InputProps={{
                  inputProps: { min: 1 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="me-2 align-middle" fontSize="small">
                        schedule_send
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                className="answer-text-green"
                onChange={(e) =>
                  handleSetObjStart("delay", parseInt(e.target.value))
                }
                onWheel={(e) => {
                  if (document.activeElement === e.target) {
                    e.target.blur(); // Blur the input to stop unwanted changes
                    setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                  }
                }}
              />
              {(props.obj.start.delay === null ||
                props.obj.start.delay === undefined ||
                props.obj.start.delay === "") && (
                <Typography variant="caption" color="error">
                  {t("Please specify start delay.")}
                </Typography>
              )}
            </Grid>
          )}
          {props.obj.start.type === "weekday" && (
            <Grid item xs={3} className="ps-2 pt-2">
              <ToggleButtonGroup
                value={props.obj.start.dayOfTheWeek}
                exclusive
                size="small"
                onChange={(e, newDayOfTheWeek) =>
                  newDayOfTheWeek &&
                  handleSetObjStart("dayOfTheWeek", newDayOfTheWeek)
                }
              >
                <ToggleButton value="Mon">{t("Mon")}</ToggleButton>
                <ToggleButton value="Tue">{t("Tue")}</ToggleButton>
                <ToggleButton value="Wed">{t("Wed")}</ToggleButton>
                <ToggleButton value="Thu">{t("Thu")}</ToggleButton>
                <ToggleButton value="Fri">{t("Fri")}</ToggleButton>
                <ToggleButton value="Sat">{t("Sat")}</ToggleButton>
                <ToggleButton value="Sun">{t("Sun")}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          {studiesStatus !== "loading" &&
            (previewToPost.length !== 0 ||
              previewToPatch.length !== 0 ||
              previewToDelete.length !== 0) && (
              <Alert severity="info" className="w-100 mt-2">
                Changing the timepoint start date will not affect tickets of
                currenty enrolled participants, only of new enrollments.
              </Alert>
            )}
        </Grid>
      </Paper>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {t("Assign groups to this timepoint")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Select
                multiple
                displayEmpty
                hiddenLabel
                size="small"
                value={props.obj.groupIdList ? props.obj.groupIdList : []}
                onChange={handleSetGroupIds}
                input={<FilledInput color="secondary" />}
                renderValue={(selected) => {
                  if (!selected || selected.length === 0) {
                    return <Chip label={t("All participants")} />;
                  }
                  return selected.map((groupId) => (
                    <Chip
                      key={groupId}
                      label={
                        props.currentGroupList.find(
                          (group) => group._id === groupId,
                        ).label
                      }
                      className="me-2"
                    />
                  ));
                }}
              >
                {props.currentGroupList.map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <StudiesEditTimepointSurveys
              obj={measurementList}
              setObj={setMeasurementList}
              setHasErrors={props.setHasErrors}
              currentGroupList={props.currentGroupList}
              surveyEntities={props.surveyEntities}
            />
          </Grid>
        </Grid>
      </Paper>
      {studiesStatus !== "loading" &&
        (previewToPost.length !== 0 ||
          previewToPatch.length !== 0 ||
          previewToDelete.length !== 0) && (
          <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {previewToPost.length === 0 &&
                previewToPatch.length === 0 &&
                previewToDelete.length === 0 ? (
                  <Typography variant="h4">
                    No tickets will be created, updated, or deleted
                  </Typography>
                ) : (
                  <Typography variant="h4">
                    Preview changes to the tickets of this timepoint
                  </Typography>
                )}
              </Grid>
              <Grid item className="ps-1 mt-1">
                <FormControlLabel
                  label={
                    props.recreateTickets.completed ? (
                      <Typography variant="body2">
                        Completed tickets may be updated and reactivated.
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        Completed tickets will not be affected.
                      </Typography>
                    )
                  }
                  control={
                    <Switch
                      size="small"
                      color="secondary"
                      checked={props.recreateTickets.completed}
                      onChange={(e) => {
                        props.setRecreateTickets((prevState) => {
                          return { ...prevState, completed: e.target.checked };
                        });
                      }}
                    />
                  }
                />
              </Grid>
              {previewToPost.length > 0 && (
                <Grid item xs={12}>
                  <Divider className="mt-2" />
                  <Typography variant="h5">To be created</Typography>
                  {previewToPost
                    .sort(
                      (x, y) =>
                        DateTime.fromISO(x.dateAvailable).valueOf() -
                        DateTime.fromISO(y.dateAvailable).valueOf(),
                    )
                    .map((ticket) => (
                      <VolunteersContentMeasurementItem
                        key={ticket._id}
                        showAvatar
                        disableButtons
                        ticket={ticket}
                        currentConsumer={consumerEntities[ticket.userId]}
                      />
                    ))}
                </Grid>
              )}
              {previewToPatch.length > 0 && (
                <Grid item xs={12}>
                  <Divider className="mt-2" />
                  <Typography variant="h5">To be updated</Typography>
                  <Typography variant="caption">
                    This list is a preview of updated ticket properties. Hover
                    to show current ticket properties.
                  </Typography>
                  {previewToPatch
                    .sort(
                      (x, y) =>
                        DateTime.fromISO(x.dateAvailable).valueOf() -
                        DateTime.fromISO(y.dateAvailable).valueOf(),
                    )
                    .map((ticket) => (
                      <div
                        key={ticket._id}
                        className={
                          !hoveredTicket[ticket._id]
                            ? "rounded border border-white bg-none "
                            : "rounded border border-dark border-dashed bg-list-item-selected"
                        }
                        onMouseEnter={() => {
                          setHoveredTicket((prevState) => {
                            return { ...prevState, [ticket._id]: true };
                          });
                        }}
                        onMouseLeave={() => {
                          setHoveredTicket((prevState) => {
                            return { ...prevState, [ticket._id]: false };
                          });
                        }}
                      >
                        <VolunteersContentMeasurementItem
                          showAvatar
                          disableButtons
                          ticket={
                            !ticket.orig
                              ? ticket
                              : !hoveredTicket[ticket._id]
                                ? ticket
                                : hoveredTicket[ticket._id]
                                  ? ticket.orig
                                  : ticket
                          }
                          currentConsumer={consumerEntities[ticket.userId]}
                        />
                      </div>
                    ))}
                </Grid>
              )}
              {previewToDelete.length > 0 && (
                <Grid item xs={12}>
                  <Divider className="mt-2" />
                  <Typography variant="h5">To be deleted</Typography>
                  {previewToDelete
                    .sort(
                      (x, y) =>
                        DateTime.fromISO(x.dateAvailable).valueOf() -
                        DateTime.fromISO(y.dateAvailable).valueOf(),
                    )
                    .map((ticket) => (
                      <VolunteersContentMeasurementItem
                        key={ticket._id}
                        showAvatar
                        disableButtons
                        ticket={ticket}
                        currentConsumer={consumerEntities[ticket.userId]}
                      />
                    ))}
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditTimepoint;
