const ZzzIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
    width="24"
    height="24"
    {...props}
  >
    {/* First Z */}
    <path
      d="M4 3 L11 3 L3 11 L11 11"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ strokeWidth: 3 }}
    />
    <path
      d="M16 8 L22 8 L15 16 L22 16"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ strokeWidth: 3 }}
    />
    <path
      d="M5 16 L10 16 L4 22 L9 22"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ strokeWidth: 3 }}
    />
  </svg>
);

export default ZzzIcon;
