// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// =================================================
// FUNCTIONAL COMPONENT
const TextboxItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValue, setLocalValue] = useState("");
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Set local state
    setLocalValue(value !== null ? value : "");
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({
            responseId,
            alias,
            value,
            disablemetadata: true,
          }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // -------------------------------------------------
  // Render input adornments
  const inputAdornment =
    props.article.options &&
    props.article.options.startAdornment &&
    props.article.options.endAdornment
      ? {
          startAdornment: (
            <InputAdornment position="start">
              {props.article.options.startAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {props.article.options.endAdornment}
            </InputAdornment>
          ),
        }
      : props.article.options &&
          props.article.options.startAdornment &&
          !props.article.options.endAdornment
        ? {
            startAdornment: (
              <InputAdornment position="start">
                {props.article.options.startAdornment}
              </InputAdornment>
            ),
          }
        : props.article.options &&
            !props.article.options.startAdornment &&
            props.article.options.endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {props.article.options.endAdornment}
                </InputAdornment>
              ),
            }
          : {};
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <TextField
        disabled={props.uneditable}
        multiline={props.multiline}
        hiddenLabel={!props.article.inputLabel}
        name={props.alias}
        type="text"
        label={props.article.inputLabel}
        value={localValue}
        color="secondary"
        variant="filled"
        className="answer-text-green mt-1"
        InputProps={inputAdornment}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        onChange={(e) => {
          setLocalValue(e.target.value);
          handleSetSingleResponse(props.alias, e.target.value);
        }}
        onWheel={(e) => {
          if (document.activeElement === e.target) {
            e.target.blur(); // Blur the input to stop unwanted changes
            setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
          }
        }}
      />
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default TextboxItem;
