// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../redux/reducers/ui";
import {
  postNewMessage,
  patchCurrentMessage,
  selectMessageListByConversationId,
} from "../redux/reducers/messages";
import { consumersSelectors } from "../redux/reducers/consumers";
// -------------------------------------------------
// Support functions
import { str2color } from "../supportFunc/str2color";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
// -------------------------------------------------
// Basic elements
import Alert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Send from "@mui/icons-material/Send";
import MoreVert from "@mui/icons-material/MoreVert";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Reply from "@mui/icons-material/Reply";
import ExpandMore from "@mui/icons-material/ExpandMore";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const MessagesContent = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Messages_Content" });
  // ===============================================
  // REFERENCES
  // -----------------------------------------------
  const child = useRef();
  const parent = useRef();
  const endOfMessages = useRef();
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const currentRole = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.primaryRole,
  );
  const isAdmin = currentRole && currentRole !== "participant";
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id,
  );
  const messageList = useSelector((state) =>
    selectMessageListByConversationId(
      state,
      props.currentConversation ? props.currentConversation._id : null,
    ),
  );
  const messageListLength = messageList ? messageList.length : 0;
  const recipientList = useSelector((state) => state.messages.recipientList);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  );
  // -----------------------------------------------
  // Contexts
  const { socket } = useSocket();
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Local state
  const [newMessage, setNewMessage] = useState("");
  const [replyToMessageId, setReplyToMessageId] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get the day of a date
  const getDay = (d) => {
    return DateTime.fromISO(d).startOf("day").toRelativeCalendar();
  };
  // -----------------------------------------------
  // Check if the current message is posten on the same day as the previous message
  const isSameDay = (i) => {
    return (
      getDay(messageList[i].createdAt) === getDay(messageList[i - 1].createdAt)
    );
  };
  // -----------------------------------------------
  // Check if the current message is posted by the same user as the previous message
  const isSameUserId = (i) => {
    return messageList[i].userId === messageList[i - 1].userId;
  };
  // -----------------------------------------------
  // Get the appropriate classnames for a message
  const getClassName = (i) => {
    let className = "px-2 py-1 max-width-80pct";
    className = isXS ? `${className} mx-1` : `${className} mx-0`;
    className =
      i !== 0 && isSameDay(i) && isSameUserId(i) && !messageList[i - 1].isNote
        ? `${className} mt-1`
        : `${className} mt-3`;
    className =
      messageList[i].userId === currentUserId
        ? `${className} bg-list-item-selected`
        : className;
    return className;
  };
  // -----------------------------------------------
  // Set the with of the new message input field container
  const handleSetInputWidth = () => {
    if (!child.current) {
      return;
    }
    child.current.style.width = `${parent.current.clientWidth}px`;
  };
  // -----------------------------------------------
  // Handle setting the new message
  const handleSetNewMessage = (e) => {
    setNewMessage(e.target.value);
  };
  // -----------------------------------------------
  // Is executed to post a new message
  const handleSubmit = async (e) => {
    // Prevent default behavior and if there is no message, return
    e.preventDefault();
    if (newMessage === "") {
      return;
    }
    // Patch the messages to the database
    dispatch(
      postNewMessage({
        socket,
        requestingUser: currentAuth,
        body: {
          data: {
            userId: currentUserId,
            conversationId: props.currentConversation._id,
            isNote: false,
            message: newMessage,
            replyToMessageId,
          },
          meta: {
            userIdList: props.currentConversation.userIdList,
          },
        },
      }),
    );
    // Reset the local state
    setNewMessage("");
    setReplyToMessageId("");
    setMenuIsOpen(false);
    // Scroll to the newest message in the chat
    endOfMessages.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  // -----------------------------------------------
  // Replaces the the orignal message with "this message was deleted"
  const handleDeleteMessage = (messageId) => {
    let newMessage = messageList.find((msg) => msg._id === messageId);
    newMessage = JSON.parse(JSON.stringify(newMessage));
    newMessage.message = "This message was deleted.";
    dispatch(
      patchCurrentMessage({
        socket,
        requestingUser: currentAuth,
        body: { data: newMessage, meta: { deleteMessage: true } },
      }),
    );
  };
  // -----------------------------------------------
  // Scroll to bottom when component renders
  useEffect(() => {
    if (endOfMessages.current) {
      endOfMessages.current.scrollIntoView();
      handleSetInputWidth();
    }
  }, [endOfMessages.current, messageList]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Automatically set all messages to is read when they come in
  useEffect(() => {
    props.handleSetConversationMessageListRead();
  }, [messageListLength]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Add event listener for when the window resizes
  useEffect(() => {
    window.addEventListener("resize", handleSetInputWidth);
    // Clean-up the event listener upon unmounting
    return () => {
      window.removeEventListener("resize", handleSetInputWidth);
    };
  }, []);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return !props.currentConversation ? (
    <AppDrawerPlaceholder
      imageURL="images/icon-choose.png"
      imageAlt={t("Choose a conversation from the list")}
      title={t("Choose a conversation from the list")}
    />
  ) : (
    <div ref={parent}>
      {messageList
        .sort(
          (a, b) =>
            DateTime.fromISO(a.createdAt).valueOf() -
            DateTime.fromISO(b.createdAt).valueOf(),
        )
        .map((msg, i) =>
          msg.isNote ? (
            <Grid container key={msg._id} justifyContent="center">
              {(i === 0 || !isSameDay(i)) && (
                <Grid item xs={12} className="text-center">
                  <Chip
                    size="small"
                    label={getDay(msg.createdAt)}
                    className="mt-3"
                  />
                </Grid>
              )}
              <Grid item>
                <Chip size="small" label={msg.message} className="mt-3" />
              </Grid>
            </Grid>
          ) : (
            <Grid
              key={msg._id}
              container
              alignItems="flex-end"
              className="px-3"
              direction={msg.userId === currentUserId ? "row-reverse" : "row"}
            >
              {(i === 0 || !isSameDay(i)) && (
                <Grid item xs={12} className="text-center">
                  <Chip
                    size="small"
                    label={getDay(msg.createdAt)}
                    className="mt-3"
                  />
                </Grid>
              )}
              <Card className={getClassName(i)}>
                {msg.replyToMessageId && msg.replyToMessageId !== "" && (
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#f0f0f0",
                      borderLeft: `3px solid ${str2color(
                        messageList.find((m) => m._id === msg.replyToMessageId)
                          .userId,
                      )}`,
                    }}
                    className="shadow-none my-1 px-2 py-1"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: str2color(
                          messageList.find(
                            (m) => m._id === msg.replyToMessageId,
                          ).userId,
                        ),
                      }}
                    >
                      {consumerEntities[
                        messageList.find((m) => m._id === msg.replyToMessageId)
                          .userId
                      ] &&
                      consumerEntities[
                        messageList.find((m) => m._id === msg.replyToMessageId)
                          .userId
                      ].name
                        ? consumerEntities[
                            messageList.find(
                              (m) => m._id === msg.replyToMessageId,
                            ).userId
                          ].name
                        : consumerEntities[
                              messageList.find(
                                (m) => m._id === msg.replyToMessageId,
                              ).userId
                            ]
                          ? consumerEntities[
                              messageList.find(
                                (m) => m._id === msg.replyToMessageId,
                              ).userId
                            ].email
                          : "John Doe"}
                    </Typography>
                    <Typography className="d-inline white-space-pre-line">
                      {messageList.find((m) => m._id === msg.replyToMessageId)
                        .message === "This message was deleted." ? (
                        <i>
                          <span className="text-secondary">
                            This message was deleted.
                          </span>
                        </i>
                      ) : (
                        messageList.find((m) => m._id === msg.replyToMessageId)
                          .message
                      )}
                    </Typography>
                  </Card>
                )}
                {(i === 0 ||
                  !isSameUserId(i) ||
                  !isSameDay(i) ||
                  messageList[i - 1].isNote) && (
                  <Typography
                    variant="h6"
                    sx={{ color: str2color(msg.userId) }}
                  >
                    {msg.userId !== currentUserId &&
                    consumerEntities[msg.userId] &&
                    consumerEntities[msg.userId].name
                      ? consumerEntities[msg.userId].name
                      : msg.userId !== currentUserId &&
                          consumerEntities[msg.userId]
                        ? consumerEntities[msg.userId].email
                        : null}
                  </Typography>
                )}
                <Typography className="d-inline white-space-pre-line">
                  {msg.message === "This message was deleted." ? (
                    <i>
                      <span className="text-secondary">{msg.message}</span>
                    </i>
                  ) : (
                    msg.message
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  className="float-right mt-2px ms-2 text-muted"
                >
                  {DateTime.fromISO(msg.createdAt).startOf("minute").toISOTime({
                    suppressSeconds: true,
                    includeOffset: false, // for display only
                  })}
                </Typography>
              </Card>
              {!recipientList.find(
                (r) => r.messageId === msg._id && r.userId === currentUserId,
              ).isRead &&
                msg.userId !== currentUserId && (
                  <Badge
                    color="error"
                    variant="dot"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  />
                )}
              {msg.message !== "This message was deleted." && (
                <Grid item className="px-2">
                  {msg.userId !== currentUserId ? (
                    <>
                      <IconButton
                        size="small"
                        onClick={(e) => setReplyToMessageId(msg._id)}
                      >
                        <Reply size="small" className="opacity-50" />
                      </IconButton>
                      {currentRole && isAdmin && (
                        <IconButton
                          id={msg._id}
                          size="small"
                          onClick={(e) => {
                            setMenuIsOpen(true);
                            setMenuAnchor(e.currentTarget);
                          }}
                        >
                          <ExpandMore size="small" className="opacity-50" />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <IconButton
                      id={msg._id}
                      size="small"
                      onClick={(e) => {
                        setMenuIsOpen(true);
                        setMenuAnchor(e.currentTarget);
                      }}
                    >
                      <ExpandMore size="small" className="opacity-50" />
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          ),
        )}
      <span ref={endOfMessages} />
      {/* MOCK DIV FOR PADDING */}
      <div className="pt-5 pb-4 opacity-0">&nbsp;</div>
      {/* ACTUAL INPUT FIELD */}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          ref={child}
          alignItems="center"
          id="new-message-input"
          className="position-fixed bottom-0 bg-light rounded shadow"
        >
          {!props.currentConversation.userIdList.some(
            (userId) => userId === currentUserId,
          ) && (
            <Grid item xs={12}>
              <Alert severity="info">
                {t(
                  "Add yourself to the group chat if you'd like to say something.",
                )}
              </Alert>
            </Grid>
          )}
          {replyToMessageId !== "" && (
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs={11}>
                  <Card
                    sx={{
                      borderLeft: `3px solid ${str2color(
                        messageList.find((m) => m._id === replyToMessageId)
                          .userId,
                      )}`,
                    }}
                    className="m-2 px-2 py-1"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: str2color(
                          messageList.find((m) => m._id === replyToMessageId)
                            .userId,
                        ),
                      }}
                    >
                      {consumerEntities[
                        messageList.find((m) => m._id === replyToMessageId)
                          .userId
                      ] &&
                      consumerEntities[
                        messageList.find((m) => m._id === replyToMessageId)
                          .userId
                      ].name
                        ? consumerEntities[
                            messageList.find((m) => m._id === replyToMessageId)
                              .userId
                          ].name
                        : consumerEntities[
                              messageList.find(
                                (m) => m._id === replyToMessageId,
                              ).userId
                            ]
                          ? consumerEntities[
                              messageList.find(
                                (m) => m._id === replyToMessageId,
                              ).userId
                            ].email
                          : null}
                    </Typography>
                    <Typography className="d-inline white-space-pre-line">
                      {
                        messageList.find((m) => m._id === replyToMessageId)
                          .message
                      }
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={(e) => setReplyToMessageId("")}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container wrap="nowrap" alignItems="center" className="p-2">
              <Grid item className="flex-grow-1 pe-1">
                <TextField
                  disabled={
                    !props.currentConversation.userIdList.some(
                      (userId) => userId === currentUserId,
                    )
                  }
                  fullWidth
                  hiddenLabel
                  color="secondary"
                  variant="filled"
                  value={newMessage}
                  onChangeCapture={handleSetNewMessage}
                />
              </Grid>
              <Grid item className="flex-grow-0 ps-1">
                <Button
                  disabled={
                    !props.currentConversation.userIdList.some(
                      (userId) => userId === currentUserId,
                    )
                  }
                  variant="contained"
                  type="submit"
                  className="py-3"
                >
                  <Send />
                </Button>
              </Grid>
              {currentRole && isAdmin && (
                <Grid item>
                  <IconButton
                    id="add"
                    color="inherit"
                    className="ms-3"
                    onClick={(e) => {
                      props.setObj(props.currentConversation);
                      props.setIsNewConversation(false);
                      dispatch(
                        toggleSecDrawer({
                          isOpen: true,
                          id: "messages_new-conversation",
                        }),
                      );
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              )}
              {currentRole && isAdmin && (
                <Grid item>
                  <IconButton
                    id="delete-conversation"
                    color="inherit"
                    className="ms-3"
                    onClick={(e) => {
                      setMenuIsOpen(true);
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Menu
        open={menuIsOpen}
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical:
            menuAnchor && menuAnchor.id === "delete-conversation"
              ? "top"
              : "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical:
            menuAnchor && menuAnchor.id === "delete-conversation"
              ? "bottom"
              : "top",
          horizontal: "right",
        }}
        onClose={() => {
          setMenuIsOpen(false);
          setMenuAnchor(null);
        }}
      >
        {menuAnchor && menuAnchor.id === "delete-conversation" ? (
          <MenuItem
            onClick={() => {
              props.handleDeleteConversation();
              setMenuIsOpen(false);
            }}
          >
            <ListItemIcon>
              <DeleteForever fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete conversation forever")}</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleDeleteMessage(menuAnchor.id);
              setMenuIsOpen(false);
            }}
          >
            <ListItemIcon>
              <DeleteForever fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete message forever")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default MessagesContent;
