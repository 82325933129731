import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  AreaChart,
  LineChart,
  BarChart,
  ScatterChart,
  CartesianGrid,
  Scatter,
  Bar,
  Area,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { DateTime, Duration } from "luxon";

const DashboardLineChart = ({
  data,
  title,
  alias,
  payload,
  options,
  cbtiTasks,
}) => {
  const theme = useTheme();

  const [avgChartData, setAvgChartData] = useState([]);
  const [dailyChartData, setDailyChartData] = useState([]);

  // Transform the data into the format required by Recharts
  useEffect(() => {
    const sortedAvgData = Object.values(data)
      .map((d) => ({ x: d.x, y: [d.md, d.md] }))
      .sort((a, b) => a.x - b.x);
    const sortedDailyData = payload.map((entry) => {
      let value = entry.taskResponse?.data[alias] || "null";
      if (value.startsWith("PT")) {
        value = Duration.fromISO(value).as("minutes");
        value = value < 1 ? 1 : value;
      } else if (title === "Sleep Quality") {
        value = parseInt(value) + 1;
      } else {
        value = parseFloat(value);
      }
      return {
        datestring: DateTime.fromISO(entry.dateAvailable).toFormat(
          "EEEEE dd/MM",
        ),
        x: DateTime.fromISO(entry.dateAvailable).startOf("day").toMillis(),
        y: value ? value : null,
      };
    });
    setDailyChartData(sortedDailyData);
    setAvgChartData(sortedAvgData);
  }, [data]);

  return (
    <Box height={150}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={dailyChartData} barGap={0} barCategoryGap={0}>
          <XAxis
            type="number"
            dataKey="x"
            scale="time"
            domain={([dataMin, dataMax]) => {
              const maxCompleted = payload
                .filter((d) => d.isCompleted)
                .map((d) =>
                  DateTime.fromISO(d.dateAvailable).toLocal().toMillis(),
                )
                .sort((a, b) => b - a)[0];
              return [dataMin - 86400000, maxCompleted + 86400000];
            }}
            tickSize={0}
            interval={0}
            tick={{ fontSize: 10, textAnchor: "end" }}
            ticks={cbtiTasks.map(
              (entry) =>
                DateTime.fromISO(entry.dateAvailable).toLocal().toMillis() -
                86400000,
            )}
            tickFormatter={(tick, i) => {
              return cbtiTasks[i]?.acronym;
            }}
            angle={-90}
          />
          <YAxis
            allowDataOverflow={true}
            domain={options.domain}
            type="number"
            label={{
              value: title,
              angle: -90,
              fontSize: 12,
            }}
            ticks={options.ticks}
            tick={{ fontSize: 10 }}
            tickSize={0}
            interval={0}
          />
          <Bar dataKey="y" stackId="abc" fill="#81C4FF" />
          {avgChartData.map((avg, i) => (
            <ReferenceLine
              key={`${title}${i}`}
              stroke="#164BA7"
              strokeWidth={3}
              segment={[
                { x: avg.x[0] - 86400000 / 4, y: avg.y[0] },
                { x: avg.x[1] - 86400000 / 4, y: avg.y[1] },
              ]}
            />
          ))}

          <CartesianGrid stroke="#888" strokeDasharray="1 3" vertical={true} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DashboardLineChart;
