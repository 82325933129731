// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/users`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the studies in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  currentStudyId: null,
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all users
// TODO only return users that an admin is supervisor of
export const fetchConsumerList = createAsyncThunk(
  "consumers/fetchConsumerList",
  async ({ requestingUser }) => {
    const response = await client.get(apiurl, requestingUser);
    return response.data;
  },
);
// -------------------------------------------------
// API fetch a single user
export const fetchConsumerById = createAsyncThunk(
  "consumers/fetchConsumerById",
  async ({ requestingUser, userId }) => {
    const response = await client.get(`${apiurl}/${userId}`, requestingUser);
    return response.data;
  },
);
// -------------------------------------------------
// API patch a user object
export const patchCurrentConsumer = createAsyncThunk(
  "consumers/patchCurrentConsumer",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body,
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-consumer", {
        userId: response.data.user._id,
      });
    // Return the response
    return response.data;
  },
);
// =================================================
// DEFINE MUTATING ACTIONS
export const consumersSlice = createSlice({
  name: "consumers",
  initialState,
  reducers: {
    resetConsumerError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    setCurrentStudyId(state, action) {
      state.currentStudyId = action.payload.currentStudyId;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchConsumerList.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchConsumerList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        action.payload.userList &&
          adapter.upsertMany(state, action.payload.userList);
      })
      .addCase(fetchConsumerList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchConsumerById.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchConsumerById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        action.payload.user && adapter.upsertOne(state, action.payload.user);
      })
      .addCase(fetchConsumerById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentConsumer.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(patchCurrentConsumer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        action.payload.user && adapter.upsertOne(state, action.payload.user);
      })
      .addCase(patchCurrentConsumer.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setCurrentStudyId, resetConsumerError } = consumersSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const consumersSelectors = adapter.getSelectors(
  (state) => state.consumers,
);
// =================================================
// EXPORT DEFAULT
export default consumersSlice.reducer;
