// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { DateTime } from "luxon";
// -------------------------------------------------
// Component elements
import CmsContent from "./CmsContent";
import StudyCards from "./StudyCards";
import FormRoot from "../Form_Root";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
// -------------------------------------------------
// Redux
import {
  fetchPublicSurveyById,
  publicSurveysSelectors,
} from "../../redux/reducers/publicSurveys";
import { setCurrentTicketByObject } from "../../redux/reducers/tickets";
import { setFormStatus } from "../../redux/reducers/form";
// -------------------------------------------------
// Basic elements
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
// =================================================
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// =================================================
// FUNCTIONAL COMPONENT
function StudyTemplate(props) {
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const getSurveyIdFromContent = () => {
    let screeningSurveyId = props.currentWebpage.contentList.filter(
      (content) => content.fieldname === "screeningQuestions",
    );
    if (screeningSurveyId.length === 0) {
      return; // No screening survey defined, return
    }
    screeningSurveyId = screeningSurveyId[0].content;
    screeningSurveyId = screeningSurveyId.replace("<p>", "");
    screeningSurveyId = screeningSurveyId.replace("</p>", "");
    return screeningSurveyId;
  };
  // -----------------------------------------------
  const loadScreener = async () => {
    // Set the dialog box to open
    setDialogIsOpen(true);
    // Extract the screening survey id
    const screeningSurveyId = getSurveyIdFromContent();
    setCurrentSurveyId(screeningSurveyId);
    let res, ticket;
    if (!screeningSurveyId) {
      dispatch(setCurrentTicketByObject({ ticket: null }));
      return;
    }
    res = await dispatch(
      fetchPublicSurveyById({
        requestingUser: currentAuth,
        surveyId: screeningSurveyId,
        upsert: true,
      }),
    );
    if (res.payload && res.payload.survey) {
      // Set artificial ticket
      ticket = {
        _id: uuid(),
        isDummy: true,
        userId: currentUserId ? currentUserId : "anonymous",
        studyId: res.payload.survey.folder,
        timepointId: uuid(),
        measurementId: uuid(),
        groupId: null,
        surveyId: screeningSurveyId,
        responseId: uuid(),
        completer: "participant",
        remainVisible: true,
        remainEditable: true,
        level: 1,
        entryNumber: 1,
        repeat: 1,
        interval: 1,
        intervalUnits: "d",
        availability: 1,
        availabilityUnits: "d",
        allowance: 1,
        allowanceUnits: "d",
        responseCollection: "taskResponses",
        dateAvailable: DateTime.now().startOf("second").toUTC().toISO({
          suppressMilliseconds: true,
        }),
        dateDue: DateTime.now()
          .plus({ days: 365 })
          .startOf("second")
          .toUTC()
          .toISO({
            suppressMilliseconds: true,
          }),
        dateExpire: DateTime.now()
          .plus({ days: 365 })
          .startOf("second")
          .toUTC()
          .toISO({
            suppressMilliseconds: true,
          }),
        hasStarted: false,
        isCompleted: false,
        viewIdx: 0,
        notificationStatus: "idle",
      };
    }
    dispatch(setCurrentTicketByObject({ ticket }));
    // Set form status to "idle" so it initializes
    dispatch(setFormStatus({ status: "idle" }));
  };
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // context
  const { currentAuth } = useAuth();
  const [currentSurveyId, setCurrentSurveyId] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id,
  );
  const currentSurvey = useSelector((state) =>
    publicSurveysSelectors.selectById(
      state,
      currentSurveyId && currentSurveyId,
    ),
  );
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const isCompleted = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.isCompleted,
  );
  // -----------------------------------------------
  // Other variables
  const { t } = useTranslation("pages", { keyPrefix: "StudyTemplate" });
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const isSM = useSelector((state) => state.ui.isSM);
  const stripHtmlTags = (str) => {
    if (str === null || str === undefined || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  };
  const extractFirstText = (str) => {
    const matches = str.match(/"(.*?)"/);
    return matches ? matches[1] : str;
  };
  const study = {
    keywords: [
      "Anxiety",
      "Depression",
      "General mental health",
      "Insomnia",
      "Restless sleep",
    ],
    title: props.currentWebpage.title,
    type:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find((item) => item.fieldname === "type")
        ?.content,
    age:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find((item) => item.fieldname === "age")
        ?.content,
    location:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "location",
      )?.content,
    commitment:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "commitment",
      )?.content,
    rewards:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "reward",
      )?.content,
    about:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "about",
      )?.content,
    instituteInfo:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "instituteInfo",
      )?.content,
    investigatorInfo:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "investigatorInfo",
      )?.content,
    investigatorImage:
      props.currentWebpage.contentList &&
      props.currentWebpage.contentList.find(
        (item) => item.fieldname === "investigatorImage",
      )
        ? extractFirstText(
            props.currentWebpage.contentList.find(
              (item) => item.fieldname === "investigatorImage",
            ).content,
          )
        : "",
  };
  // -----------------------------------------------
  const StudyPropComponent = (props) => (
    <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
      <Grid item sx={{ lineHeight: "1rem" }}>
        <Icon color="secondary" className="mt-2 me-2">
          {props.icon}
        </Icon>
      </Grid>
      <Grid item>
        <Typography
          variant="overline"
          color="secondary"
          sx={{ lineHeight: "1rem" }}
        >
          {props.title}
        </Typography>
        <br />
        <Typography sx={{ lineHeight: "1rem" }}>
          {stripHtmlTags(props.value)}
        </Typography>
      </Grid>
    </Grid>
  );
  // -----------------------------------------------
  // Other variables
  return (
    <>
      <Dialog
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        scroll="paper"
        maxWidth="md"
        onClose={() => {
          setDialogIsOpen(false);
          dispatch(setCurrentTicketByObject({ ticket: null }));
        }}
        open={dialogIsOpen}
        className="w-100"
        PaperProps={{
          className: "bg-grey m-0 mt-5 h-100 w-100 mh-100",
        }}
      >
        <Box className="px-4 pt-3 bg-grey">
          <Button
            startIcon={<Icon>clear</Icon>}
            color="inherit"
            onClick={() => {
              setDialogIsOpen(false);
              dispatch(setCurrentTicketByObject({ ticket: null }));
            }}
          >
            {isCompleted ? t("Close") : t("Cancel")}
          </Button>
          {currentSurvey && currentTicket && !isCompleted && <FormRoot />}
          {isCompleted && (
            <div className="text-center mb-3">
              <img
                src="images/icon-hooray.svg"
                alt="Thank you very much"
                height="250"
                className="text-center max-width-80pct"
              />
              <Typography variant="h3">
                {t("Thank you very much. If you are eligible, we will contact you.")}
              </Typography>
            </div>
          )}
        </Box>
      </Dialog>
      <Box
        sx={{
          backgroundImage: "url('images/sleepfix/bg-02.png')",
          backgroundOrigin: "border-box",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container
          sx={{
            paddingTop: "64px",
            paddingBottom: "32px",
          }}
        >
          <Grid container spacing={3} className="py-3 pt-5">
            <Grid item xs={12} sm={8} className="py-3">
              {study.keywords.map((kw) => (
                <Chip key={kw} label={kw} className="me-2 mb-2" />
              ))}
              <Typography variant="h1" className="my-3">
                {study.title}
              </Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <StudyPropComponent
                    icon="category"
                    title={t("Type")}
                    value={study.type}
                  />
                </Grid>
                <Grid item>
                  <StudyPropComponent
                    icon="family_restroom"
                    title={t("Age")}
                    value={study.age}
                  />
                </Grid>
                <Grid item>
                  <StudyPropComponent
                    icon="place"
                    title={t("Location")}
                    value={study.location}
                  />
                </Grid>
                <Grid item>
                  <StudyPropComponent
                    icon="date_range"
                    title={t("Time commitment")}
                    value={study.commitment}
                  />
                </Grid>
                <Grid item>
                  <StudyPropComponent
                    icon="emoji_events"
                    title={t("Rewards and expenses")}
                    value={study.rewards}
                  />
                </Grid>
              </Grid>
              <Divider className="my-3" />
              <Typography variant="h3" className="my-2">
                {t("About the study")}
              </Typography>
              <Card className="p-3">
                <CmsContent
                  currentWebpage={props.currentWebpage}
                  fieldname="about"
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} className="pt-3">
              {getSurveyIdFromContent() && (
                <Button
                  fullWidth
                  variant="contained"
                  className="mb-3"
                  onClick={loadScreener}
                >
                  {t("I am interested")}
                </Button>
              )}
              <Avatar
                src={study.investigatorImage}
                sx={{
                  margin: "auto",
                  width: 150,
                  height: 150,
                  zIndex: 1000,
                }}
                className="shadow"
              />
              <Card
                sx={{
                  position: "relative",
                  top: "-75px",
                  marginBottom: "-50px",
                  padding: "85px 25px 25px 25px",
                  zIndex: 900,
                  fontSize: isSM ? "0.8rem" : "1rem",
                }}
                className="bg-list-item-selected"
              >
                <CmsContent
                  currentWebpage={props.currentWebpage}
                  fieldname="investigatorInfo"
                />
              </Card>
              <Typography variant="body2">
                {
                  <CmsContent
                    currentWebpage={props.currentWebpage}
                    fieldname="instituteInfo"
                  />
                }
              </Typography>
            </Grid>
            <StudyCards hide={props.currentWebpage._id} />
          </Grid>
        </Container>
      </Box>
    </>
  );
}
// =================================================
// EXPORT COMPONENT
export default StudyTemplate;
