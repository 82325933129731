// =================================================
// IMPORT
import {
  current,
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
// =================================================
// Helper functions
import { evaluateScoring } from "../../supportFunc/evaluateScoring";
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// -------------------------------------------------
// Patch a responses object
export const patchPreviewResponses = createAsyncThunk(
  "previewResponses/patchPreviewResponses",
  async ({ body }) => {
    // Return the response
    return { previewResponse: body.data };
  },
);
// =================================================
// USE MIDDLEWARE TO GET ACCESS TO OTHER SLICES
// -------------------------------------------------
// Set survey logic after changing one single response
export const setSinglePreviewResponseByAlias = createAsyncThunk(
  "previewResponses/setSinglePreviewResponseByAlias",
  (payload, thunk) => {
    const state = thunk.getState();
    const surveyId = state.tickets.currentTicket.surveyId;
    const isEnabled = state.form.isEnabled;
    let survey;
    if (
      state.surveys &&
      state.surveys.entities &&
      state.surveys.entities[surveyId]
    ) {
      survey = state.surveys.entities[surveyId];
    } else if (
      state.publicSurveys &&
      state.publicSurveys.entities &&
      state.publicSurveys.entities[surveyId]
    ) {
      survey = state.publicSurveys.entities[surveyId];
    }
    const scoring = survey.scoring;
    const surveyAcronym = survey.acronym;
    const aliasList = survey.aliasList;
    return { ...payload, scoring, surveyAcronym, aliasList, isEnabled };
  },
);
// =================================================
// DEFINE MUTATING ACTIONS
export const previewResponsesSlice = createSlice({
  name: "previewResponses",
  initialState,
  reducers: {
    setInitPreviewResponses(state, action) {
      if (action.payload.initResponses === -1) {
        state.status = "failed";
        state.errorMsg = "Could not initialize survey responses.";
      } else {
        adapter.addOne(state, action.payload.initResponses);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(patchPreviewResponses.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.previewResponse &&
          adapter.upsertOne(state, action.payload.previewResponse);
      })
      .addCase(setSinglePreviewResponseByAlias.fulfilled, (state, action) => {
        // Extract the aliases (one or more), the values (one or more, must be equal length), and the response-id
        const aliases = Array.isArray(action.payload.alias)
          ? action.payload.alias
          : [action.payload.alias];
        const values = Array.isArray(action.payload.value)
          ? action.payload.value
          : [action.payload.value];
        const responseId = action.payload.responseId;
        // Check if the response-id exists
        if (!current(state).entities[responseId]) {
          return;
        }
        // Extract the response data for shorthand
        const responseData = current(state).entities[responseId].data;
        // Check that the alias list is equal length as the values list
        if (aliases.length !== values.length) {
          state.status = "failed";
          state.errorMsg = "Mismatch between alias count and value count.";
          return;
        }
        // Set the response data
        for (let i = 0; i < aliases.length; i++) {
          // Shorthand
          const alias = aliases[i];
          const value = values[i];
          // Check that the alias exists
          if (!Object.keys(responseData).includes(alias)) {
            state.status = "failed";
            state.errorMsg = `Could not find the alias '${alias}' in the response data.`;
            return;
          }
          // Update the response data
          state.entities[responseId].data[alias] = value;
          // Add metadata if needed
          if (!action.payload.disablemetadata && value !== null) {
            state.entities[responseId].metadata.push({
              timestamp: DateTime.now().toUTC().toISO(),
              [alias]: value,
            });
          }
        }
        // Now run the scoring arithmetics and grouping items in order of appearance
        const scoring = action.payload.scoring;
        const acronym = action.payload.surveyAcronym;
        const aliasList = action.payload.aliasList;
        const isEnabled = action.payload.isEnabled;
        if (scoring) {
          for (let i = 0; i < scoring.length; i++) {
            state.entities[responseId].data[`${acronym}_${scoring[i].alias}`] =
              evaluateScoring(
                scoring[i],
                current(state).entities[responseId].data,
                aliasList,
                isEnabled,
              );
          }
        }
        // If this is the first response, set the start time
        if (!state.entities[responseId].dateStarted) {
          state.entities[responseId].dateStarted = DateTime.now()
            .startOf("second")
            .toUTC()
            .toISO({ suppressMilliseconds: true });
        }
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setInitPreviewResponses } = previewResponsesSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const previewResponsesSelectors = adapter.getSelectors(
  (state) => state.previewResponses,
);
// =================================================
// EXPORT DEFAULT
export default previewResponsesSlice.reducer;
