// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Duration } from "luxon";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
// =================================================
// FUNCTIONAL COMPONENT
const DurationItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.DurationItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // Extract the days, hours, minutes and seconds
  const nElements =
    props.article.options.showDays +
    props.article.options.showHours +
    props.article.options.showMinutes +
    props.article.options.showSeconds;
  const dur = value ? Duration.fromISO(value) : null;
  const [days, setDays] = useState(value ? dur.days : null);
  const [hours, setHours] = useState(value ? dur.hours : null);
  const [minutes, setMinutes] = useState(value ? dur.minutes : null);
  const [seconds, setSeconds] = useState(value ? dur.seconds : null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <Grid container alignItems="center" wrap="nowrap">
        {props.article.options.showDays && (
          <Grid item xs={12 / nElements} className="pe-2">
            <TextField
              fullWidth
              disabled={props.uneditable}
              name={props.alias}
              type="number"
              label={t("Days")}
              value={days ? days : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1"
              onChange={(e) => {
                setDays(e.target.value);
                let newValue = {
                  days: e.target.value === "" ? null : e.target.value,
                  hours: props.article.options.showHours ? hours : null,
                  minutes: props.article.options.showMinutes ? minutes : null,
                  seconds: props.article.options.showSeconds ? seconds : null,
                };
                newValue.days = newValue.days === "" ? null : newValue.days;
                newValue.hours = newValue.hours === "" ? null : newValue.hours;
                newValue.minutes =
                  newValue.minutes === "" ? null : newValue.minutes;
                newValue.seconds =
                  newValue.seconds === "" ? null : newValue.seconds;
                newValue = Object.values(newValue).every((val) => val === null)
                  ? null
                  : Duration.fromObject(newValue).toISO();
                handleSetSingleResponse(props.alias, newValue);
              }}
              onWheel={(e) => {
                if (document.activeElement === e.target) {
                  e.target.blur(); // Blur the input to stop unwanted changes
                  setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                }
              }}
            />
          </Grid>
        )}
        {props.article.options.showHours && (
          <Grid item xs={12 / nElements} className="pe-2">
            <TextField
              fullWidth
              disabled={props.uneditable}
              name={props.alias}
              type="number"
              label={t("Hours")}
              value={hours ? hours : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1"
              onChange={(e) => {
                setHours(e.target.value);
                let newValue = {
                  days: props.article.options.showDays ? days : null,
                  hours: e.target.value === "" ? null : e.target.value,
                  minutes: props.article.options.showMinutes ? minutes : null,
                  seconds: props.article.options.showSeconds ? seconds : null,
                };
                newValue.days = newValue.days === "" ? null : newValue.days;
                newValue.hours = newValue.hours === "" ? null : newValue.hours;
                newValue.minutes =
                  newValue.minutes === "" ? null : newValue.minutes;
                newValue.seconds =
                  newValue.seconds === "" ? null : newValue.seconds;
                newValue = Object.values(newValue).every((val) => val === null)
                  ? null
                  : Duration.fromObject(newValue).toISO();
                handleSetSingleResponse(props.alias, newValue);
              }}
              onWheel={(e) => {
                if (document.activeElement === e.target) {
                  e.target.blur(); // Blur the input to stop unwanted changes
                  setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                }
              }}
            />
          </Grid>
        )}
        {props.article.options.showMinutes && (
          <Grid item xs={12 / nElements} className="pe-2">
            <TextField
              fullWidth
              disabled={props.uneditable}
              name={props.alias}
              type="number"
              label={t("Minutes")}
              value={minutes ? minutes : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1"
              onChange={(e) => {
                setMinutes(e.target.value);
                let newValue = {
                  days: props.article.options.showDays ? days : null,
                  hours: props.article.options.showHours ? hours : null,
                  minutes: e.target.value === "" ? null : e.target.value,
                  seconds: props.article.options.showSeconds ? seconds : null,
                };
                newValue.days = newValue.days === "" ? null : newValue.days;
                newValue.hours = newValue.hours === "" ? null : newValue.hours;
                newValue.minutes =
                  newValue.minutes === "" ? null : newValue.minutes;
                newValue.seconds =
                  newValue.seconds === "" ? null : newValue.seconds;
                newValue = Object.values(newValue).every((val) => val === null)
                  ? null
                  : Duration.fromObject(newValue).toISO();
                handleSetSingleResponse(props.alias, newValue);
              }}
              onWheel={(e) => {
                if (document.activeElement === e.target) {
                  e.target.blur(); // Blur the input to stop unwanted changes
                  setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                }
              }}
            />
          </Grid>
        )}
        {props.article.options.showSeconds && (
          <Grid item xs={12 / nElements} className="pe-2">
            <TextField
              fullWidth
              disabled={props.uneditable}
              name={props.alias}
              type="number"
              label={t("Seconds")}
              value={seconds ? seconds : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1"
              onChange={(e) => {
                setSeconds(e.target.value);
                let newValue = {
                  days: props.article.options.showDays ? days : null,
                  hours: props.article.options.showHours ? hours : null,
                  minutes: props.article.options.showMinutes ? minutes : null,
                  seconds: e.target.value === "" ? null : e.target.value,
                };
                newValue.days = newValue.days === "" ? null : newValue.days;
                newValue.hours = newValue.hours === "" ? null : newValue.hours;
                newValue.minutes =
                  newValue.minutes === "" ? null : newValue.minutes;
                newValue.seconds =
                  newValue.seconds === "" ? null : newValue.seconds;
                newValue = Object.values(newValue).every((val) => val === null)
                  ? null
                  : Duration.fromObject(newValue).toISO();
                handleSetSingleResponse(props.alias, newValue);
              }}
              onWheel={(e) => {
                if (document.activeElement === e.target) {
                  e.target.blur(); // Blur the input to stop unwanted changes
                  setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default DurationItem;
