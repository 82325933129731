// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { ticketsSelectors } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Helper functions
import { parseDaysClockString } from "../../supportFunc/parseDaysClockString";
import { defaultStudies } from "../../supportFunc/defaultStudies";
// -------------------------------------------------
// Component elements
import StudiesEditTimepoint from "./Studies_EditTimepoint";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// -------------------------------------------------
// Icons
import Icon from "@mui/material/Icon";
import Save from "@mui/icons-material/Save";
import Add from "@mui/icons-material/Add";
import ScheduleSend from "@mui/icons-material/ScheduleSend";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Groups from "@mui/icons-material/Groups";
import Assignment from "@mui/icons-material/Assignment";
import Star from "@mui/icons-material/Star";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContentTimepointItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_ContentTimepointItem",
  });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const studiesStatus = useSelector((state) => state.studies.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const [hasErrors, setHasErrors] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [timepoint, setTimepoint] = useState(props.timepoint);
  const [idx, setIdx] = useState(props.idx);
  const [deleteMenuIsOpen, setDeleteMenuIsOpen] = useState(false);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get suffix of ordinal number
  const ordinalSuffixOf = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };
  // -----------------------------------------------
  // Handles opening the top drawer for adding a timepoint
  const handleAdd = (idx) => {
    const newTimepoint = defaultStudies.timepoint;
    newTimepoint._id = uuid();
    setTimepoint(newTimepoint);
    setIdx(idx);
    dispatch(toggleSecDrawer({ isOpen: true, id: newTimepoint._id }));
  };

  // -----------------------------------------------
  // Handles opening the top drawer for changing study info
  const handleEdit = (id) => {
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setTimepoint(props.timepoint);
      setIdx(props.idx);
    }
  }, [props.timepoint, props.idx, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={timepoint._id}
        title={t("Edit timepoint")}
        buttons={
          <Button
            disabled={
              !enableSaveButton || hasErrors || studiesStatus === "loading"
            }
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={async () => {
              setEnableSaveButton(false);
              await props.handleMutateCurrentStudy("timepoint", timepoint, idx);
              setEnableSaveButton(true);
            }}
          >
            {!enableSaveButton || studiesStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        <StudiesEditTimepoint
          idx={idx}
          obj={timepoint}
          setObj={setTimepoint}
          currentStudy={props.currentStudy}
          recreateTickets={props.recreateTickets}
          setRecreateTickets={props.setRecreateTickets}
          setHasErrors={setHasErrors}
          currentGroupList={props.currentGroupList}
          surveyEntities={props.surveyEntities}
          enrolledConsumerList={props.enrolledConsumerList}
          ordinalSuffixOf={ordinalSuffixOf}
        />
      </TopDrawer>
      {/* SECTION SHOWING CURRENT TIMEPOINT */}
      <TimelineItem key={props.timepoint._id} className="w-100">
        {/* TIMEPOINT CONNECTOR */}
        {props.isLast ? (
          <TimelineDot />
        ) : (
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
        )}
        {/* TIMELINE CONTENT */}
        <TimelineContent color="textSecondary" className="py-0 pe-0">
          <Grid container>
            <Grid item xs={12}>
              {/* TIMEPOINT LABEL */}
              <Typography variant="h5" className="pb-2">
                {props.timepoint && props.timepoint.label
                  ? props.timepoint.label
                  : "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={props.canBeEdited ? 11 : 12}>
              <Card className="px-2 me-2 mb-1 border border-secondary shadow-none">
                <List disablePadding>
                  {/* TIMEPOINT START */}
                  <ListItem
                    disablePadding
                    divider
                    alignItems="flex-start"
                    className="align-items-center py-2"
                  >
                    <ListItemIcon className="ps-3 m-0">
                      <ScheduleSend />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography variant="caption">
                          {props.timepoint.start.type === "manual"
                            ? "manual"
                            : props.timepoint.start.type === "weekday"
                              ? `${ordinalSuffixOf(
                                  props.timepoint.start.delay,
                                )} ${props.timepoint.start.dayOfTheWeek} since ${
                                  props.idx === 0
                                    ? "enrollment"
                                    : "previous timepoint onset"
                                }`
                              : props.timepoint.start.delay === 1
                                ? `same day as ${
                                    props.idx === 0
                                      ? "enrollment"
                                      : "previous timepoint onset"
                                  }`
                                : props.timepoint.start.delay === 2
                                  ? `day after ${
                                      props.idx === 0
                                        ? "enrollment"
                                        : "previous timepoint onset"
                                    }`
                                  : `${ordinalSuffixOf(
                                      props.timepoint.start.delay,
                                    )} day since ${
                                      props.idx === 0
                                        ? "enrollment"
                                        : "previous timepoint onset"
                                    }`}
                        </Typography>
                      }
                      className="m-0 pe-3"
                    />
                  </ListItem>
                  {/* GROUPS */}
                  <ListItem
                    disablePadding
                    divider
                    alignItems="flex-start"
                    className="align-items-center py-2"
                  >
                    <ListItemIcon className="ps-3 m-0">
                      <Groups className="d-block" />
                    </ListItemIcon>
                    {!props.timepoint.groupIdList ? (
                      <Typography variant="caption">
                        {t("All participants")}
                      </Typography>
                    ) : (
                      props.timepoint.groupIdList.map((id) => (
                        <Chip
                          key={id}
                          size="small"
                          variant="outlined"
                          className="me-1"
                          label={
                            props.currentGroupList.find(
                              (group) => group._id === id,
                            ).label
                          }
                        />
                      ))
                    )}
                  </ListItem>
                  {/* SURVEYS */}
                  <Accordion disableGutters className="mb-2">
                    <AccordionSummary
                      className="p-0"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <ListItem
                        disablePadding
                        alignItems="flex-start"
                        className="align-items-center "
                      >
                        <ListItemIcon className="ps-3 m-0">
                          <Assignment className="d-block" />
                        </ListItemIcon>
                        {props.timepoint.measurementList ? (
                          <Typography variant="caption">
                            {`${props.timepoint.measurementList.length} surveys`}
                          </Typography>
                        ) : (
                          <Typography variant="caption">No surveys</Typography>
                        )}
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className="p-0">
                      {!props.timepoint.measurementList ? (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          className="mx-4"
                        >
                          {t("None")}
                        </Typography>
                      ) : (
                        props.timepoint.measurementList.map((s) => (
                          <React.Fragment key={s._id}>
                            {props.surveyEntities[s.surveyId] ? (
                              <Grid
                                container
                                wrap="nowrap"
                                alignItems="center"
                                justifyContent="space-between"
                                className="w-100 p-2 bg-light border"
                              >
                                <Grid item>
                                  <Tooltip
                                    arrow
                                    placement="right"
                                    title={
                                      props.surveyEntities[s.surveyId].name
                                        .official
                                    }
                                  >
                                    <Chip
                                      label={
                                        props.surveyEntities[s.surveyId].acronym
                                      }
                                      icon={
                                        props.surveyEntities[s.surveyId]
                                          .folder === "standardized" ? (
                                          <Star className="ps-1" />
                                        ) : null
                                      }
                                      size="small"
                                      className="me-2"
                                      onClick={() =>
                                        navigate(
                                          `/surveys?surveyId=${s.surveyId}`,
                                          {
                                            replace: false,
                                          },
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                  >
                                    <Grid item xs="auto">
                                      {s.completer === "participant" ? (
                                        <Tooltip
                                          title="completed by user"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              account_circle
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="completed by investigator"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              supervised_user_circle
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs="auto"
                                      className="text-center"
                                    >
                                      {s.remainVisible ? (
                                        <Tooltip
                                          title="Remains in task list upon completion"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              visibility
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="Disapears upon completion"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              disabled_visible
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    <Grid item xs="auto">
                                      {s.remainEditable ? (
                                        <Tooltip
                                          title="Remains editable after completion"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              published_with_changes
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="Not editable after completion"
                                          placement="top"
                                          arrow
                                        >
                                          <span className="me-2 align-middle font-size-065rem">
                                            <Icon
                                              fontSize="small"
                                              className="align-middle"
                                            >
                                              unpublished
                                            </Icon>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    <Grid item xs="auto">
                                      <Tooltip
                                        title={`level ${s.level}`}
                                        placement="top"
                                        arrow
                                      >
                                        <span className="me-2 align-middle font-size-065rem">
                                          <Icon
                                            fontSize="small"
                                            className="me-1 align-middle"
                                          >
                                            low_priority
                                          </Icon>
                                          {s.level}
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs="auto">
                                      <Tooltip
                                        title={`start at ${parseDaysClockString(
                                          s.delay,
                                        )}`}
                                        placement="top"
                                        arrow
                                      >
                                        <span className="me-2 align-middle font-size-065rem">
                                          <Icon
                                            fontSize="small"
                                            className="me-1 align-middle"
                                          >
                                            schedule_send
                                          </Icon>
                                          {parseDaysClockString(s.delay)}
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs="auto">
                                      <Tooltip
                                        title={
                                          s.availability === -1
                                            ? "available indefinately"
                                            : s.allowance > 0
                                              ? `available for ${
                                                  s.availability +
                                                  s.availabilityUnit
                                                } + ${
                                                  s.allowance + s.allowanceUnit
                                                } allowance`
                                              : `available for ${
                                                  s.availability +
                                                  s.availabilityUnit
                                                }`
                                        }
                                        placement="top"
                                        arrow
                                      >
                                        <span className="me-2 align-middle font-size-065rem">
                                          <Icon
                                            fontSize="small"
                                            className="me-1 align-middle"
                                          >
                                            lock_clock
                                          </Icon>
                                          {s.availability === -1 ? (
                                            <Typography className="d-inline">
                                              &infin;
                                            </Typography>
                                          ) : (
                                            s.availability + s.availabilityUnit
                                          )}
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs="auto">
                                      <Tooltip
                                        title={
                                          s.repeat === 1
                                            ? "No repeat"
                                            : "Repeat"
                                        }
                                        placement="top"
                                        arrow
                                      >
                                        <span className="me-0 align-middle font-size-065rem">
                                          <Icon
                                            fontSize="small"
                                            className="me-1 align-middle"
                                          >
                                            {s.repeat === 1
                                              ? "repeat_one"
                                              : "repeat"}
                                          </Icon>
                                          {s.repeat === -1
                                            ? "∞ @ " +
                                              s.interval +
                                              s.intervalUnit
                                            : s.repeat === 1
                                              ? "no repeat"
                                              : s.repeat +
                                                "x @ " +
                                                s.interval +
                                                s.intervalUnit}
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Alert severity="error" className="w-100">
                                <Typography
                                  variant="caption"
                                  className="d-block"
                                >{`Survey does not exist (ID: ${s.surveyId}).`}</Typography>
                              </Alert>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </AccordionDetails>
                  </Accordion>
                </List>
              </Card>
            </Grid>
            {props.canBeEdited && (
              <Grid item xs={1}>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item>
                    <Tooltip arrow title={t("Edit")} placement="left">
                      <IconButton
                        disabled={studiesStatus === "loading"}
                        edge="end"
                        onClick={() => handleEdit(props.timepoint._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {!ticketList.some(
                    (ticket) => ticket.timepointId === props.timepoint._id,
                  ) && (
                    <Grid item>
                      <Tooltip arrow title={t("Copy")} placement="left">
                        <span>
                          <IconButton
                            disabled={studiesStatus === "loading"}
                            edge="end"
                            onClick={() => props.handleCopy(props.idx)}
                          >
                            <ContentCopy />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                  {/* <Grid item>
                    <Tooltip
                      arrow
                      title={
                        props.idx === 0 ? t("Can't move up") : t("Move up")
                      }
                      placement="left"
                    >
                      <span>
                        <IconButton
                          disabled={
                            studiesStatus === "loading" || props.idx === 0
                          }
                          edge="end"
                          onClick={() => props.handleMove(props.idx, -1)}
                        >
                          <ArrowUpward />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      title={
                        props.isLast ? t("Can't move down") : t("Move down")
                      }
                      placement="left"
                    >
                      <span>
                        <IconButton
                          disabled={studiesStatus === "loading" || props.isLast}
                          edge="end"
                          onClick={() => props.handleMove(props.idx, 1)}
                        >
                          <ArrowDownward />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid> */}
                  {!ticketList.some(
                    (ticket) => ticket.timepointId === props.timepoint._id,
                  ) && (
                    <Grid item>
                      <Tooltip
                        arrow
                        title={
                          props.hasOneItem ? t("Can't delete") : t("Delete")
                        }
                        placement="left"
                      >
                        <span>
                          <IconButton
                            disabled={
                              studiesStatus === "loading" || props.hasOneItem
                            }
                            edge="end"
                            onClick={(e) => {
                              setDeleteMenuIsOpen(true);
                              setDeleteMenuAnchor(e.currentTarget);
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Menu
                  anchorEl={deleteMenuAnchor}
                  open={deleteMenuIsOpen}
                  onClose={() => {
                    setDeleteMenuIsOpen(false);
                    setDeleteMenuAnchor(null);
                  }}
                >
                  <MenuItem onClick={() => props.handleDelete(props.idx)}>
                    {studiesStatus === "loading" ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      t("Delete")
                    )}
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
          {props.canBeEdited && (
            <div>
              <Divider className="pe-3 pt-2">
                <ButtonGroup>
                  <Button
                    disabled={studiesStatus === "loading"}
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<Add />}
                    className="border-dashed"
                    onClick={() => handleAdd(props.idx + 1)}
                  >
                    {t("Add timepoint")}
                  </Button>
                </ButtonGroup>
              </Divider>
            </div>
          )}
        </TimelineContent>
      </TimelineItem>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContentTimepointItem;
