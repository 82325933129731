// =================================================
// IMPORT
import {
  current,
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
// =================================================
// Helper functions
import { evaluateScoring } from "../../supportFunc/evaluateScoring";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/task-responses`;
const publicapiurl = `${rooturl}/public/task-responses`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch a task response by its id
export const fetchTaskResponseById = createAsyncThunk(
  "taskResponses/fetchTaskResponseById",
  async ({ requestingUser, taskId }) => {
    const response = await client.get(`${apiurl}/${taskId}`, requestingUser);
    return response.data;
  },
);
// -------------------------------------------------
// API fetch all task responses from one user and study
export const fetchTaskResponsesFromOneUser = createAsyncThunk(
  "taskResponses/fetchTaskResponsesFromOneUser",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/user/${userId}`,
      requestingUser,
    );
    return response.data;
  },
);
// -------------------------------------------------
// API fetch all task responses from one user and study
export const fetchTaskResponsesFromOwnedStudies = createAsyncThunk(
  "taskResponses/fetchTaskResponsesFromOwnedStudies",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/study-user/${userId}`,
      requestingUser,
    );
    return response.data;
  },
);
// -------------------------------------------------
// API post a responses object
export const patchTaskResponses = createAsyncThunk(
  "taskResponses/patchTaskResponses",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    let response;
    if (!requestingUser || body.data.userId === "anonymous") {
      response = await client.patch(
        `${publicapiurl}/${body.data._id}`,
        requestingUser,
        body,
      );
    } else {
      response = await client.patch(
        `${apiurl}/${body.data._id}`,
        requestingUser,
        body,
      );
    }
    // Invoke event on server
    socket && socket.emit("patched-task-response", { taskId: body.data._id });
    socket &&
      response.data.surveyId &&
      socket.emit("patched-current-survey", {
        surveyId: response.data.surveyId,
      });
    // Return the response
    return response.data;
  },
);
// =================================================
// USE MIDDLEWARE TO GET ACCESS TO OTHER SLICES
// -------------------------------------------------
// Set survey logic after changing one single response
export const setSingleTaskResponseByAlias = createAsyncThunk(
  "taskResponses/setSingleTaskResponseByAlias",
  (payload, thunk) => {
    const state = thunk.getState();
    const surveyId = state.tickets.currentTicket.surveyId;
    const isEnabled = state.form.isEnabled;
    let survey;
    if (
      state.surveys &&
      state.surveys.entities &&
      state.surveys.entities[surveyId]
    ) {
      survey = state.surveys.entities[surveyId];
    } else if (
      state.publicSurveys &&
      state.publicSurveys.entities &&
      state.publicSurveys.entities[surveyId]
    ) {
      survey = state.publicSurveys.entities[surveyId];
    }
    const scoring = survey.scoring;
    const surveyAcronym = survey.acronym;
    const aliasList = survey.aliasList;
    return { ...payload, scoring, surveyAcronym, aliasList, isEnabled };
  },
);
// =================================================
// DEFINE MUTATING ACTIONS
export const taskResponsesSlice = createSlice({
  name: "taskResponses",
  initialState,
  reducers: {
    resetTaskResponsesError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    setInitTaskResponses(state, action) {
      if (action.payload.initResponses === -1) {
        state.status = "failed";
        state.errorMsg = "Could not initialize survey responses.";
      } else {
        adapter.addOne(state, action.payload.initResponses);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSingleTaskResponseByAlias.fulfilled, (state, action) => {
        // Extract the aliases (one or more), the values (one or more, must be equal length), and the response-id
        const aliases = Array.isArray(action.payload.alias)
          ? action.payload.alias
          : [action.payload.alias];
        const values = Array.isArray(action.payload.value)
          ? action.payload.value
          : [action.payload.value];
        const responseId = action.payload.responseId;
        // Check if the response-id exists
        if (!current(state).entities[responseId]) {
          return;
        }
        // Extract the response data for shorthand
        const responseData = current(state).entities[responseId].data;
        // Check that the alias list is equal length as the values list
        if (aliases.length !== values.length) {
          state.status = "failed";
          state.errorMsg = "Mismatch between alias count and value count.";
          return;
        }
        // Set the response data
        for (let i = 0; i < aliases.length; i++) {
          // Shorthand
          const alias = aliases[i];
          const value = values[i];
          // Check that the alias exists
          if (!Object.keys(responseData).includes(alias)) {
            state.status = "failed";
            state.errorMsg = `Could not find the alias '${alias}' in the response data.`;
            return;
          }
          // Update the response data
          state.entities[responseId].data[alias] = value;
          // Add metadata if needed
          if (!action.payload.disablemetadata && value !== null) {
            state.entities[responseId].metadata.push({
              timestamp: DateTime.now().toUTC().toISO(),
              [alias]: value,
            });
          }
        }
        // Now run the scoring arithmetics and grouping items in order of appearance
        const scoring = action.payload.scoring;
        const acronym = action.payload.surveyAcronym;
        const aliasList = action.payload.aliasList;
        const isEnabled = action.payload.isEnabled;
        if (scoring) {
          for (let i = 0; i < scoring.length; i++) {
            state.entities[responseId].data[`${acronym}_${scoring[i].alias}`] =
              evaluateScoring(
                scoring[i],
                current(state).entities[responseId].data,
                aliasList,
                isEnabled,
              );
          }
        }
        // If this is the first response, set the start time
        if (!state.entities[responseId].dateStarted) {
          state.entities[responseId].dateStarted = DateTime.now()
            .startOf("second")
            .toUTC()
            .toISO({ suppressMilliseconds: true });
        }
      })
      .addCase(fetchTaskResponseById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.taskResponse &&
          adapter.upsertOne(state, action.payload.taskResponse);
      })
      .addCase(fetchTaskResponseById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchTaskResponsesFromOneUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskResponsesFromOneUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.taskResponseList &&
          adapter.setAll(state, action.payload.taskResponseList);
      })
      .addCase(fetchTaskResponsesFromOneUser.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchTaskResponsesFromOwnedStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTaskResponsesFromOwnedStudies.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          action.payload.taskResponseList &&
            adapter.setAll(state, action.payload.taskResponseList);
        },
      )
      .addCase(fetchTaskResponsesFromOwnedStudies.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchTaskResponses.fulfilled, (state, action) => {
        state.status = "succeeded";
        // EDIT 03-06-2024 by RW
        // We do not need to upsert the task response, because the Redux state contains
        // the latest version of the task responses. Furthermore, the API call is performed
        // asynchronously, so the user may have answered the next question before this API
        // call is returned: if we were to upsert the local state, we might reset some
        // answers that were submitted since the last API call.
        // action.payload.taskResponse &&
        //   adapter.upsertOne(state, action.payload.taskResponse);
      })
      .addCase(patchTaskResponses.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setInitTaskResponses, resetTaskResponsesError } =
  taskResponsesSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const taskResponsesSelectors = adapter.getSelectors(
  (state) => state.taskResponses,
);
// =================================================
// EXPORT DEFAULT
export default taskResponsesSlice.reducer;
