// =================================================
// IMPORTS
// -------------------------------------------------
// React and Redux
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";

// Contexts
import { useAuth } from "../contexts/auth";

// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import DashboardCBTIColumnContent from "../components/DashboardCBTI_ColumnContent";
import DashboardCBTIContent from "../components/DashboardCBTI_Content";
import DashboardCBTIOverview from "../components/DashboardCBTI_Overview";
import FloatingChatButton from "../components/dashboard-cbti/FloatingChatButton";

// Redux actions and selectors
import { fetchStudyList, studiesSelectors } from "../redux/reducers/studies";
import { supervisionSelectors } from "../redux/reducers/supervision";
import { fetchConsumerList } from "../redux/reducers/consumers";
import { fetchSurveyList } from "../redux/reducers/surveys";
import { taskResponsesSelectors } from "../redux/reducers/taskResponses";

// Other dependencies
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import { MenuItem, Select, FormControl } from "@mui/material";

import { Duration } from "luxon";

import SchoolIcon from "@mui/icons-material/School";
import ListItemIcon from "@mui/material/ListItemIcon";

// =================================================
// HELPER FUNCTIONS
// -------------------------------------------------

// Extract all survey IDs from a study
const getAllSurveyIds = (study) => {
  const surveyIds = new Set();
  if (study && Array.isArray(study.timepointList)) {
    study.timepointList.forEach((timepoint) => {
      if (timepoint && Array.isArray(timepoint.measurementList)) {
        timepoint.measurementList.forEach((measurement) => {
          if (measurement && measurement.surveyId) {
            surveyIds.add(measurement.surveyId);
          }
        });
      }
    });
  }
  return Array.from(surveyIds);
};

// Extract survey acronyms from a study
const getSurveyAcronyms = (study, surveyList) => {
  const surveyIds = getAllSurveyIds(study);
  return surveyIds
    .map((surveyId) => surveyList[surveyId]?.acronym)
    .filter(Boolean);
};

// Function to calculate mean
function calculateMean(arr) {
  const sum = arr.reduce((acc, num) => acc + num, 0); // Sum all elements
  return sum / arr.length; // Divide sum by number of elements
}

// Function to calculate median
function calculateMedian(arr) {
  const sorted = arr.slice().sort((a, b) => a - b); // Sort ascending
  const mid = Math.floor(sorted.length / 2);

  return sorted.length % 2 !== 0
    ? sorted[mid] // Odd length → middle element
    : (sorted[mid - 1] + sorted[mid]) / 2; // Even length → average of two middle elements
}

// Compute weekly average scores for a given data key
const computeWeeklyScore = (tasks, cbtiTickets, dataKey) => {
  if (!tasks) {
    return {};
  }
  const weeklyData = {};
  let cbtiDates = cbtiTickets
    .sort((a, b) => b.acronym.localeCompare(a.acronym))
    .map((ticket) => ticket.dateAvailable);
  cbtiDates.push(
    DateTime.fromISO(cbtiDates[0]).plus({ week: 1 }).toUTC().toISO(),
  );
  cbtiDates = cbtiDates.sort((a, b) => a.localeCompare(b));

  tasks
    .sort((a, b) => a.dateAvailable.localeCompare(b.dateAvailable))
    .forEach((task) => {
      const response = task.taskResponse;
      if (response && response.data && response.data[dataKey]) {
        let value;
        if (
          typeof response.data[dataKey] === "string" &&
          response.data[dataKey].startsWith("PT")
        ) {
          const duration = Duration.fromISO(response.data[dataKey]);
          value = duration.as("minutes");
        } else {
          value = parseFloat(response.data[dataKey]);
        }

        // Assign value to pre-CBTI, module #, or post-CBTI
        if (!isNaN(value)) {
          const isLaterDate = cbtiDates.map((isodate) => {
            return (
              DateTime.fromISO(response.dateAvailable)
                .startOf("day")
                .diff(DateTime.fromISO(isodate).startOf("day"))
                .toMillis() < 0
            );
          });
          const lastTrueIndex = isLaterDate.lastIndexOf(false) + 1;
          if (!weeklyData[lastTrueIndex]) {
            weeklyData[lastTrueIndex] = {};
            weeklyData[lastTrueIndex].x = [];
            weeklyData[lastTrueIndex].y = [];
          }
          weeklyData[lastTrueIndex].x.push(response.dateAvailable);
          weeklyData[lastTrueIndex].y.push(value);
        }
      }
    });
  // Compute the median for each phase (pre-CBTIm, module #, and post-CBTI)
  const avgData = {};
  for (const key in weeklyData) {
    avgData[key] = {};
    avgData[key].x = [
      DateTime.fromISO(weeklyData[key].x[0])
        .toLocal()
        .minus({ day: 1 })
        .toMillis(),
      DateTime.fromISO(weeklyData[key].x[weeklyData[key].x.length - 1])
        .toLocal()
        .toMillis(),
    ];
    avgData[key].mu = calculateMean(weeklyData[key].y);
    avgData[key].md = calculateMedian(weeklyData[key].y);
  }
  return avgData;
};

// =================================================
// COMPONENT
// -------------------------------------------------

const DashboardCBTI = () => {
  // ===============================================
  // CONTEXTS AND STATE
  // -----------------------------------------------
  const { currentAuth } = useAuth();

  const [studyId, setStudyId] = useState(null);
  const [showOverview, setShowOverview] = useState(true);
  const [therapists, setTherapists] = useState([]);
  const [resultsByUser, setResultsByUser] = useState({});
  const [currentModules, setCurrentModules] = useState([]); // eslint-disable-line no-unused-vars
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const dispatch = useDispatch();

  // Redux selectors
  const studiesStatus = useSelector((state) => state.studies.status);
  const studiesList = useSelector((state) => studiesSelectors.selectAll(state));
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, studyId),
  );
  const supervisionEntities = useSelector((state) =>
    supervisionSelectors.selectEntities(state),
  );
  const consumersStatus = useSelector((state) => state.consumers.status);
  const consumerEntities = useSelector((state) => state.consumers.entities);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const surveyEntities = useSelector((state) => state.surveys.entities);
  const taskResponseList = useSelector((state) =>
    taskResponsesSelectors.selectAll(state),
  );
  const ticketsEntities = useSelector((state) => state.tickets.entities);

  // ===============================================
  // DATA FETCHING AND PROCESSING
  // -----------------------------------------------

  // Fetch studies
  useEffect(() => {
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
  }, [studiesStatus, dispatch, currentAuth]);

  // Fetch surveys
  useEffect(() => {
    if (surveysStatus === "idle" && Object.keys(surveyEntities).length === 0) {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
  }, [surveysStatus, dispatch, currentAuth, surveyEntities]);

  // Fetch consumers
  useEffect(() => {
    if (consumersStatus === "idle") {
      dispatch(fetchConsumerList({ requestingUser: currentAuth }));
    }
  }, [consumersStatus, dispatch, currentAuth]);

  // Set the most recent CBTI study ID
  useEffect(() => {
    if (!studiesList.length || !surveyEntities) return;

    const cbtiStudies = studiesList.filter((study) => {
      const surveyAcronyms = getSurveyAcronyms(study, surveyEntities);
      return surveyAcronyms.some((acronym) => acronym.startsWith("CBCTI"));
    });

    setStudyId(cbtiStudies[0]?._id);
  }, [studiesList, surveyEntities]);

  // Process therapists and participants
  useEffect(() => {
    if (!currentStudy || !consumerEntities || !supervisionEntities) return;

    // Get participants in the current study
    const participants = Object.values(consumerEntities)
      .filter(
        (consumer) =>
          !consumer.deletedOn &&
          consumer.primaryRole &&
          consumer.primaryRole.trim().toLowerCase() === "participant" &&
          Array.isArray(consumer.studyEnrollmentList) &&
          consumer.studyEnrollmentList.some(
            (enrollment) =>
              enrollment.studyId &&
              enrollment.studyId.toString() === studyId.toString(),
          ),
      )
      .map((consumer) => ({
        id: consumer._id,
        userNumber: consumer.userNumber,
        email: consumer.email,
        createdAt: consumer.createdAt,
        lastSignInOn: consumer.lastSignInOn,
      }));

    // Get all users (e.g., researchers, therapists) in the current study
    const supervisors = currentStudy.userIdList
      .map((userId) => consumerEntities[userId])
      .filter((user) => user); // Ensure the user exists

    // Map supervisors to their assigned participants
    const supervisionArray = Object.values(supervisionEntities);
    const therapistParticipantsMap = supervisors.reduce((acc, supervisor) => {
      const assignedParticipants = participants.filter((participant) =>
        supervisionArray.some(
          (entity) =>
            entity.isAllParticipants ||
            (entity.userId === supervisor._id &&
              entity.studyId === studyId &&
              entity.userIdList?.includes(participant.id)),
        ),
      );
      if (assignedParticipants.length > 0) {
        acc[supervisor._id] = {
          ...supervisor,
          participants: assignedParticipants,
        };
      }
      return acc;
    }, {});

    const therapistsArray = Object.values(therapistParticipantsMap);

    // Participants without a therapist
    const assignedParticipantIds = new Set(
      therapistsArray.flatMap((therapist) =>
        therapist.participants.map((p) => p.id),
      ),
    );
    const unassignedParticipants = participants.filter(
      (participant) => !assignedParticipantIds.has(participant.id),
    );
    if (unassignedParticipants.length) {
      therapistsArray.push({
        name: "Unassigned",
        _id: "unassigned",
        participants: unassignedParticipants,
      });
    }

    // Remove therapists with no participants assigned
    const therapistsWithParticipants = therapistsArray.filter(
      (therapist) => therapist.participants.length > 0,
    );

    setTherapists(therapistsWithParticipants);
  }, [currentStudy, consumerEntities, supervisionEntities, studyId]);

  // Load task responses and tickets
  useEffect(() => {
    if (
      !studyId ||
      !taskResponseList ||
      !currentStudy ||
      !surveyEntities ||
      !ticketsEntities
    )
      return;

    // Get CBTI and sleep log survey IDs
    const cbtiSurveyIds = getAllSurveyIds(currentStudy).filter((surveyId) =>
      surveyEntities[surveyId]?.acronym.startsWith("CBCTI"),
    );

    const sleepLogSurveyIds = getAllSurveyIds(currentStudy).filter((surveyId) =>
      surveyEntities[surveyId]?.acronym.startsWith("CSD"),
    );
    // Helper function to process tickets by user
    const processTicketsByUser = (surveyIds) => {
      return Object.values(ticketsEntities)
        .filter(
          (ticket) =>
            ticket.studyId === studyId && surveyIds.includes(ticket.surveyId),
        )
        .map((ticket) => ({
          ...ticket,
          acronym: surveyEntities[ticket.surveyId]?.acronym || "Unknown",
          taskResponse: taskResponseList.find(
            (response) => response._id === ticket.responseId,
          ),
        }))
        .reduce((acc, ticket) => {
          acc[ticket.userId] = acc[ticket.userId] || [];
          acc[ticket.userId].push(ticket);
          return acc;
        }, {});
    };

    // Process CBTI and sleep log tickets
    const cbtiTicketsByUser = processTicketsByUser(cbtiSurveyIds);
    const sleepLogTicketsByUser = processTicketsByUser(sleepLogSurveyIds);

    // Compute sleep efficiency scores for each participant
    const results = {};

    for (const userId in { ...sleepLogTicketsByUser, ...cbtiTicketsByUser }) {
      const sleepLogTasks = sleepLogTicketsByUser[userId];
      const totalSleepTime = computeWeeklyScore(
        sleepLogTasks,
        cbtiTicketsByUser[userId],
        "CSD_TOTAL_SLEEP_TIME",
      );
      const sleepEfficiency = computeWeeklyScore(
        sleepLogTasks,
        cbtiTicketsByUser[userId],
        "CSD_SLP_EFF_TST_TIB",
      );
      const sleepOnsetLatency = computeWeeklyScore(
        sleepLogTasks,
        cbtiTicketsByUser[userId],
        "CSD5",
      );
      const wakeAfterSleepOnset = computeWeeklyScore(
        sleepLogTasks,
        cbtiTicketsByUser[userId],
        "CSD7",
      );
      const sleepQuality = computeWeeklyScore(
        sleepLogTasks,
        cbtiTicketsByUser[userId],
        "CSD11",
      );

      results[userId] = {
        sleepLogTasks,
        totalSleepTime,
        sleepEfficiency,
        sleepOnsetLatency,
        wakeAfterSleepOnset,
        sleepQuality,
        cbtiTasks: cbtiTicketsByUser[userId] || [],
      };
    }
    setResultsByUser(results);
  }, [
    studyId,
    taskResponseList,
    currentStudy,
    surveyEntities,
    ticketsEntities,
  ]);

  // Set current modules
  useEffect(() => {
    if (!currentStudy || !surveyEntities) return;

    const cbtiSurveyIds = getAllSurveyIds(currentStudy).filter((surveyId) =>
      surveyEntities[surveyId]?.acronym.startsWith("CBCTI"),
    );

    const modules = Object.values(surveyEntities).filter((survey) =>
      cbtiSurveyIds.includes(survey._id),
    );

    setCurrentModules(modules);
  }, [currentStudy, surveyEntities]);

  // Handle study selection
  const handleStudyChange = (event) => {
    setStudyId(event.target.value);
    setShowOverview(true);
  };

  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <AppRoot>
      {showOverview ? null : (
        <FloatingChatButton consumerId={selectedParticipant?.id} />
      )}
      <Helmet>
        <title>{currentStudy ? currentStudy.name : "CBTI Dashboard"}</title>
      </Helmet>
      <Grid container className="w-100" wrap="nowrap">
        <ScrollColumn>
          <FormControl fullWidth>
            <Select
              labelId="study-select-label"
              value={studyId}
              onChange={handleStudyChange}
              displayEmpty
              inputProps={{ "aria-label": "Without outline" }}
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: 0 } }}
            >
              {studiesList
                .filter((study) => {
                  const surveyAcronyms = getSurveyAcronyms(
                    study,
                    surveyEntities,
                  );
                  return surveyAcronyms.some((acronym) =>
                    acronym.startsWith("CBCTI"),
                  );
                })
                .map((study) => (
                  <MenuItem key={study._id} value={study._id}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <ListItemIcon sx={{ minWidth: "8px" }} className="me-2">
                          <SchoolIcon sx={{ mr: 1 }} />
                        </ListItemIcon>
                      </Grid>
                      <Grid item>{study.name}</Grid>
                    </Grid>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <DashboardCBTIColumnContent
            showOverview={showOverview}
            setShowOverview={setShowOverview}
            therapists={therapists}
            setSelectedParticipant={setSelectedParticipant}
          />
        </ScrollColumn>
        <ContentPanel
          title={
            showOverview
              ? "Overview"
              : `${selectedParticipant?.email} (${selectedParticipant?.userNumber})`
          }
          isLoading={false}
        >
          {showOverview ? (
            <DashboardCBTIOverview
              setShowOverview={setShowOverview}
              setSelectedParticipant={setSelectedParticipant}
              resultsByUser={resultsByUser}
              therapists={therapists}
            />
          ) : (
            <DashboardCBTIContent
              selectedParticipant={selectedParticipant}
              participantData={
                selectedParticipant
                  ? resultsByUser[selectedParticipant.id]
                  : null
              }
            />
          )}
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};

// =================================================
// EXPORT COMPONENT
export default DashboardCBTI;
